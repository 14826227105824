<template>
  <div class="_404">
    <div>
      <div style="font-size: 30vh">
        404
      </div>
      <div style="opacity:.7">
        <h2>{{ $t("404. Сторінку не знайдено") }}</h2>
      </div>

      <el-button type="success" class="go_home_btn" block @click="goHome">{{ $t("Додому") }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error404",
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
._404 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.go_home_btn {
  background: #fff;
  color: #3dba61;
}
</style>
