export default function() {
  var beginDay = new Date();
  beginDay.setHours(0);
  beginDay.setMinutes(0);
  beginDay.setSeconds(0);
  beginDay.setMilliseconds(0);
  var endDay = new Date();
  endDay.setHours(23);
  endDay.setMinutes(59);
  endDay.setSeconds(59);
  endDay.setMilliseconds(0);
  return {
    contracts: [],
    wait: false,
    contract: null,
    filterByDateFrom: null,
    //filterByDateFrom: beginDay,
    filterByDateTo: null,
    //filterByDateTo: endDay,
  };
}
