import { _ } from "vue-underscore";

export function init(context) {
  context.commit("init");
}

export function load({ commit }, { id }) {
  return new Promise((resolve, reject) => {
    commit("templates_load", []);
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/list",
      data: { contractId: id },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_load", resp.data.data || []);
          resolve(resp);
        } else {
          commit("templates_load", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("templates_load", []);
        reject(err);
      });
  });
}

export function loadOne({ commit }, { id, id2 }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    commit("main/update_global_loading", "Завантаження умови...", { root: true });
    axios({
      url: "/api/template/get",
      data: { contractId: id, templateId: id2 },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_loadOne", resp.data.data || []);
          resolve(resp);
        } else {
          commit("templates_loadOne", null);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("templates_loadOne", null);
        reject(err);
      });
  });
}
export function create({ commit }, newRestrict) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/create",
      data: newRestrict,
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_create", resp.data.data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function update({ commit }, updateTemplate) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/update",
      data: updateTemplate,
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_update", updateTemplate.data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function del({ commit }, deleted) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/delete",
      data: deleted,
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_delete", deleted.templateId);
          commit("restricts/restricts_update_org_unset_template", deleted.templateId, { root: true });
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getRestrictsWithThisTemplate({ commit }, { contractId, templateId }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/getRestrictsListWithThisTemplate",
      data: { contractId, templateId },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function setWaitStatus({ commit }, waitStatus) {
  commit("templates_set_wait", waitStatus);
}

export function insertGroup({ commit }, { contractId, templateId, data }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/insertGroup",
      data: { contractId, templateId, data },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_insert_group", resp.data.data);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateGroup({ commit }, { contractId, templateId, groupId, data }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/updateGroup",
      data: { contractId, templateId, groupId, data },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_update_group", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteGroup({ commit }, { contractId, templateId, data }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/deleteGroup",
      data: { contractId, templateId, data },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_delete_group", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function insertSKU({ commit }, { contractId, templateId, sku }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/insertSKU",
      data: { contractId, templateId, data: sku },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_insert_sku", sku || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteSKU({ commit }, { contractId, templateId, data }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/deleteSKU",
      data: { contractId, templateId, data },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_delete_sku", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSKU({ commit }, { contractId, templateId, data }) {
  return new Promise((resolve, reject) => {
    commit("templates_set_wait", true);
    axios({
      url: "/api/template/updateSKU",
      data: { contractId, templateId, data },
      method: "POST",
    })
      .finally(() => {
        commit("templates_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("templates_update_sku", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function clearOne({ commit }) {
  commit("templates_loadOne", null);
}

export function downloadAttachedFile({ commit }, { contractId, templateId, fileId, fileName }) {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/template/file/get`,
      data: { contractId, templateId, fileId },
      method: "POST",
      responseType: "blob",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data.success !== false || resp.data.type == "application/octet-stream" || resp.data.type == "application/pdf") {
          const blob = new Blob([resp.data], { type: resp.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          resolve();
        } else {
          if (resp.data && resp.data.data && resp.data.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function(event) {
              try {
                var jsonErr = JSON.parse(reader.result);
                reject(jsonErr && jsonErr.msg ? jsonErr.msg : jsonErr);
              } catch (err) {
                reject(reader.result);
              }
            };
            reader.readAsText(resp.data.data);
          } else {
            reject("Unknown load error");
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadAttachedSKUFile({ commit }, { contractId, templateId, drugsId, fileId, fileName }) {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/template/skufile/get`,
      data: { contractId, templateId, drugsId, fileId },
      method: "POST",
      responseType: "blob",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data.success !== false || resp.data.type == "application/octet-stream" || resp.data.type == "application/pdf") {
          const blob = new Blob([resp.data], { type: resp.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          resolve();
        } else {
          if (resp.data && resp.data.data && resp.data.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function(event) {
              try {
                var jsonErr = JSON.parse(reader.result);
                reject(jsonErr && jsonErr.msg ? jsonErr.msg : jsonErr);
              } catch (err) {
                reject(reader.result);
              }
            };
            reader.readAsText(resp.data.data);
          } else {
            reject("Unknown load error");
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
