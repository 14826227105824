import { contracts_set_filter_by_date_from } from "./mutations";

export function init(context) {
  context.commit("init");
  //context.dispatch("load");
}

export function load({ commit }) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    commit("main/update_global_loading", this._vm.$i18n.translate("Завантаження договорів..."), { root: true });
    axios({
      url: "/api/contract/list",
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("contracts_load", resp.data.data || []);
          resolve(resp);
        } else {
          commit("contracts_load", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("contracts_load", []);
        reject(err);
      });
  });
}

export function loadOne({ commit }, { id, silentLoad }) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    if (!silentLoad) commit("main/update_global_loading", this._vm.$i18n.translate("Завантаження договору..."), { root: true });
    axios({
      url: "/api/contract/get",
      data: { contractId: id },
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("contracts_loadOne", resp.data.data || []);
          resolve(resp);
        } else {
          commit("contracts_loadOne", null);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("contracts_loadOne", null);
        reject(err);
      });
  });
}

export function loadContractsLegacy({ commit }, contractId) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    axios({
      url: "/apilegacy/commerce/get-docs",
      data: { contractId: contractId },
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loadStructures({ commit }, { id }) {
  commit("orgs/orgs_set_wait", true, { root: true });
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/contract/get/structures",
      data: { contractId: id },
      method: "POST",
    })
      .finally(() => {
        commit("orgs/orgs_set_wait", false, { root: true });
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          resolve(resp.data.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function create({ commit }, newContract) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);

    axios({
      url: "/api/contract/create",
      data: { data: newContract },
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("contracts_create", resp.data.data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function copy({ commit }, { copyContractId, newName, beginDate, endDate }) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    axios({
      url: "/api/contract/copy",
      data: { contractId: copyContractId, name: newName, beginDate, endDate },
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("contracts_create", resp.data.data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function clearContractBeforeSave(updatedContract) {
  if (updatedContract) {
    delete updatedContract.fullLoaded;
    delete updatedContract._providersCount;
    delete updatedContract._skuCount;
    delete updatedContract._restrictsCount;
    delete updatedContract._structuresCount;
  }
  return updatedContract;
}

export function update({ commit }, updatedContract) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    axios({
      url: "/api/contract/update",
      data: { contractId: updatedContract._id, data: clearContractBeforeSave(updatedContract) },
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("contracts_update", updatedContract || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSKUDictionary(context, { id, sku }) {
  return new Promise((resolve, reject) => {
    if (!context.state.contract) {
      loadOne(context, { id })
        .then(() => updateSKUDictionary(context, { id, sku }))
        .catch((err) => {
          reject(err);
        });
    } else {
      context.commit("contracts_update_sku_dictionary", sku || []);
      axios({
        url: "/api/contract/update",
        data: { contractId: id, data: { name: context.state.contract.name, sku: context.state.contract.sku } },
        method: "POST",
      })
        .finally(() => {})
        .then((resp) => {
          if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
            resolve(resp.data);
          } else {
            reject(resp.data.msg);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
}

export function updateStructures(context, { id, structures }) {
  return new Promise((resolve, reject) => {
    structures.forEach((org) => {
      delete org.plan;
      delete org.planFixed;
    });
    if (!context.state.contract) {
      loadOne(context, { id })
        .then(() => updateStructures(context, { id, structures }))
        .catch((err) => {
          reject(err);
        });
    } else {
      context.commit("contracts_update_structures", structures || []);
      axios({
        url: "/api/contract/update",
        data: { contractId: id, data: { name: context.state.contract.name, structures: context.state.contract.structures } },
        method: "POST",
      })
        .finally(() => {})
        .then((resp) => {
          if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
            resolve(resp.data);
          } else {
            reject(resp.data.msg);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
}

export function del({ commit }, deletedContractId) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    axios({
      url: "/api/contract/delete",
      data: { contractId: deletedContractId },
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("contracts_delete", deletedContractId);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadAttachedFile({ commit }, { contractId, fileId, fileName }) {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/contract/file/get`,
      data: { contractId, fileId },
      method: "POST",
      responseType: "blob",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data.success !== false || resp.data.type == "application/octet-stream" || resp.data.type == "application/pdf") {
          const blob = new Blob([resp.data], { type: resp.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          resolve();
        } else {
          if (resp.data && resp.data.data && resp.data.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function(event) {
              try {
                var jsonErr = JSON.parse(reader.result);
                reject(jsonErr && jsonErr.msg ? jsonErr.msg : jsonErr);
              } catch (err) {
                reject(reader.result);
              }
            };
            reader.readAsText(resp.data.data);
          } else {
            reject("Unknown load error");
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function setFilterByDateFrom({ commit }, filterByDateFrom) {
  commit("contracts_set_filter_by_date_from", filterByDateFrom);
}

export function setFilterByDateTo({ commit }, filterByDateTo) {
  commit("contracts_set_filter_by_date_to", filterByDateTo);
}

export function exportAllStructToXLSX({ commit }) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    axios({
      url: "/api/contract/listAllStructuresInAllContracts",
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function exportStructToXLSX({ commit }, contractId) {
  return new Promise((resolve, reject) => {
    commit("contracts_set_wait", true);
    axios({
      url: "/api/contract/listAllStructuresInContracts",
      data: { contractId: contractId },
      method: "POST",
    })
      .finally(() => {
        commit("contracts_set_wait", false);
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
