import { array } from "js-md5";
import jwtDecode from "jwt-decode";

export function isLoggedIn(state) {
  return function() {
    if (!state.token) {
      return false;
    } else {
      return !isTokenExpired(state.token);
    }
  };
}

export function getToken(state) {
  return function() {
    if (!state.token) {
      return null;
    } else {
      return state.token;
    }
  };
}

export function corp(state) {
  return function() {
    return state.corp;
  };
}

export function status(state) {
  return state.status == "loading";
}

export function profile(state) {
  return function() {
    return state.userSettings;
  };
}

export function serverInfo(state) {
  return function() {
    return state.serverInfo;
  };
}

export function corps(state) {
  if (!state.token) {
    return [];
  } else {
    return state.corps;
  }
}

export function corpsFiltered(state) {
  return function(filter) {
    if (!state.token) {
      return [];
    } else {
      var filterByName = filter && filter != "" ? filter : null;
      return state.corps.filter((item) => {
        if (filterByName && item.corp_name.toLowerCase().search(filterByName.toLowerCase()) == -1) {
          return false;
        } else {
          return true;
        }
      });
    }
  };
}

function getTokenExpirationDate(encodedToken) {
  let token = jwtDecode(encodedToken);
  if (!token.exp) {
    return null;
  }

  let date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired(token) {
  let expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export function left(state) {
  return state.left;
}

export function userSettings(state) {
  return function(name) {
    if (state.userSettings[name]) return state.userSettings[name];
    else return null;
  };
}

export function userSettingsTotal(state) {
  return function() {
    if (state.userSettings) return state.userSettings;
    else return null;
  };
}

export function userSettingsIsEmpty(state) {
  return state.userSettings ? Object.keys(state.userSettings).length == 0 : true;
}

export function locale(state) {
  return state.locale;
}

export function isNeedToSelectCorp(state) {
  if (!state.token) {
    return false;
  } else {
    return !jwtDecode(state.token).orgId;
  }
}

export function isNeedToLoadUserProfile(state) {
  return state.userProfile ? Object.keys(state.userProfile).length == 0 : true;
}

export function userProfile(state) {
  return function() {
    return state.userProfile;
  };
}

export function timer() {
  return function(name) {
    return state.timers[name];
  };
}

export function selectionOptions(state) {
  return state.selectionOptions;
}

function getPropertyByStringPath(o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export function selectionOptionsGetValue(state) {
  return function(path, findFunc) {
    var obj = getPropertyByStringPath(state.selectionOptions, path);
    if (obj && Array.isArray(obj)) return obj.find(findFunc);
    else return null;
  };
}

export function getFilterByDateFrom(state) {
  return function() {
    return state.filterByDateFrom;
  };
}

export function getFilterByDateTo(state) {
  return function() {
    return state.filterByDateTo;
  };
}
