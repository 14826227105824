import Vue from "vue";
import Vuex from "vuex";

// load vuex i18n module
import vuexI18n from "vuex-i18n";

const store = new Vuex.Store();

Vue.use(vuexI18n.plugin, store);

import en from "../resources/en";
import ua from "../resources/ua";

// add translations directly to the application
Vue.i18n.add("en", en);
Vue.i18n.add("ua", ua);
