export default function() {
  return {
    status: "",
    token: localStorage.getItem("token") || "",
    corp: localStorage.getItem("corp") ? JSON.parse(localStorage.getItem("corp")) : null,
    _isLoggedIn: !!localStorage.getItem("token"),
    //userSettings: {
    //   userSettings: {
    //     defaultCatalogLang: "ua",
    //     filterContractsByDate: "none",
    //   },
    //},
    filterByDateFrom: null,
    filterByDateTo: null,
    locale: localStorage.getItem("locale") || "ua",
    axiosCancelTokens: [],
    globalLoading: null,
    userProfile: {},
    timers: [],
    corps: [],
    serverInfo: {},
    selectionOptions: {
      contracts: {
        currency: [
          { value: "UAH", label: "Українська гривня (UAH)" },
          { value: "USD", label: "Американський долар (USD)" },
          { value: "EUR", label: "Євро (EUR)" },
        ],
        planMeasure: [
          { value: "money", label: "Гроші" },
          { value: "pcs", label: "Штуки" },
        ],
      },
      restricts: {
        type: [
          { value: "purchase", label: "Закупівля" },
          { value: "sale", label: "Продаж" },
          { value: "stock", label: "Товарний залишок" },
          { value: "action", label: "Акція" },
          { value: "set", label: "Викладка" },
          { value: "priority", label: "Першочергова рекомендація" },
        ],
        planMeasure: [
          { value: "pcs", label: "Штуки" },
          { value: "tabs", label: "Пігулки" },
          { value: "money", label: "Гроші" },
          { value: "share", label: "Доля" },
        ],
        bonusMeasure: [
          { value: "pctOfSIPPrice", label: "Відсоток від CIP ціни" },
          { value: "pctOfPurchase", label: "Відсоток від закупки" },
          { value: "pctOfPurchaseWithTax", label: "Відсоток від закупки з ПДВ" },
          { value: "pctOfSalePrice", label: "Відсоток від відпускної ціни" },
          { value: "pctOfSalePriceWithTax", label: "Відсоток від відпускної ціни з ПДВ" },
          { value: "money", label: "Фіксований, в грошах" },
        ],
        planAlgType: [
          { value: "shareByTurnover", label: "Згідно з долею товарообігу" },
          { value: "shareEqually", label: "Рівномірно" },
          { value: "shareFixed", label: "Фіксований, на кожну аптеку" },
        ],
        turnoverPeriodType: [
          { value: "last3Months", label: "за останні 3 місяці" },
          { value: "last6Months", label: "за останні 6 місяців" },
          { value: "last12Months", label: "за останні 12 місяців" },
        ],
      },
      sku: {
        priority: [
          { value: "high", label: "Високий" },
          { value: "normal", label: "Звичайний" },
          { value: "low", label: "Низький" },
        ],
      },
      userProfile: {
        defaultCatalogLang: [
          { value: "ua", label: "Українська (UA)" },
          { value: "en", label: "Англійська (EN)" },
          // { value: "ru", label: "Російська (RU)" },
        ],
        filterContractsByDate: [
          { value: "none", label: "Не фільтрувати" },
          { value: "2week", label: "Тиждень назад, тиждень вперед" },
          { value: "2month", label: "Місяць назад, місяць вперед" },
          { value: "currentQuarter", label: "Поточний квартал" },
        ],
        defaultCustomer: {},
        defaultExecutor: {},
        onOff: [
          { value: "true", label: "Включити" },
          { value: "false", label: "Виключити" },
        ],
      },
      importOrgWithPlan: {
        importPlanPolicy: [
          { value: "clear", label: "Видалити структури перед імпортом" },
          { value: "updateExists", label: "Доповнити планом структури, що співпадають" },
          { value: "insertNew", label: "Додати тільки нові структури" },
        ],
      },
    },
  };
}
