export function init(context) {
  context.commit("init");
}

export function get_list({ commit }, filter) {
  return new Promise((resolve, reject) => {
    commit("sku_set_wait", true);
    axios({
      url: "/api/drugs/get/sku",
      data: { data: filter },
      method: "POST",
    })
      .finally(() => {
        commit("sku_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("sku_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("sku_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("sku_update", []);
        reject(err);
      });
  });
}

export function get_info({ commit }, filter) {
  return new Promise((resolve, reject) => {
    commit("sku_set_wait_info", true);
    axios({
      url: "/api/drugs/get/sku/info",
      data: { data: filter }, // {drugsId,outputLang}
      method: "POST",
    })
      .finally(() => {
        commit("sku_set_wait_info", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("sku_info_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("sku_info_update", null);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("sku_info_update", null);
        reject(err);
      });
  });
}

export function set_info({ commit }, info) {
  commit("sku_info_update", info);
}

export function reset_info({ commit }) {
  commit("sku_info_update", null);
}

export function get_groups_list({ commit }) {
  return new Promise((resolve, reject) => {
    commit("sku_set_wait", true);
    axios({
      url: "/api/drugs/get/groups",
      method: "POST",
    })
      .finally(() => {
        commit("sku_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("sku_update_groups", resp.data.data);
          resolve(resp.data);
        } else {
          commit("sku_update_groups", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("sku_update_groups", []);
        reject(err);
      });
  });
}

export function get_list_by_node({ commit }, { node, lang }) {
  return new Promise((resolve, reject) => {
    commit("sku_set_wait", true);
    axios({
      url: "/api/drugs/get/listByNode",
      data: { structureId: node.structureId, nodes: [{ id: node.id, is_group: node.is_group }], lang: lang },
      method: "POST",
    })
      .finally(() => {
        commit("sku_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("sku_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("sku_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("sku_update", []);
        reject(err);
      });
  });
}

export function del({ commit }, sku) {
  commit("sku_del", sku);
}

export function add({ commit }, sku) {
  commit("sku_add", sku);
}

export function importFromUploadedFileXLS({ commit }, { fileName, params }) {
  return new Promise((resolve, reject) => {
    commit("sku_set_wait", true);
    commit("sku_import_update", []);
    axios(
      {
        url: "/api/drugs/importFromXLS",
        data: { fileName, ...params },
        method: "POST",
      },
      { timeout: 1000 * 60 * 5 } //!
    )
      .finally(() => {
        commit("sku_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("sku_import_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("sku_import_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("sku_import_update", []);
        reject(err);
      });
  });
}

export function get_restrict_list_by_sku({ commit }, drugsId) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/drugs/get/sku/restrictsList",
      data: { drugsId },
      method: "POST",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
