import { _ } from "vue-underscore";

export function init(state) {
  state.bonusMatrix = { contracts: [], orgs: [], coeff: [], _coeff: {} };
  state.wait = false;
  state.history = [];
}

// Analytics mutations
export function analytics_set_wait(state, wait) {
  state.wait = wait;
}

export function analytics_bonus_matrix_update(state, matrix) {
  state.bonusMatrix.orgs = matrix.orgs ? matrix.orgs : [];
  state.bonusMatrix.contracts = matrix.contracts ? matrix.contracts : [];
  state.bonusMatrix.coeff = matrix.coeff ? matrix.coeff : [];
  state.bonusMatrix._coeff = {}; // for fast search
  matrix.coeff.forEach((coeff) => {
    state.bonusMatrix._coeff[coeff._id.contractId + "_" + coeff._id.axiomaId] = coeff;
  });
}

export function analytics_bonus_matrix_history_update(state, history) {
  state.history = history ? history : [];
}

export function analytics_bonus_matrix_update_coeff(state, data) {
  //var item = state.bonusMatrix.coeff.find((i) => i._id.axiomaId == data.axiomaId && i._id.contractId == data.contractId);
  var item = state.bonusMatrix._coeff[data.contractId + "_" + data.axiomaId];
  if (item) {
    item.coeff = data.coeff;
    item.historyCount++;
  } else {
    var newCoeff = { _id: { axiomaId: data.axiomaId, contractId: data.contractId }, coeff: data.coeff, historyCount: 1 };
    state.bonusMatrix._coeff[data.contractId + "_" + data.axiomaId] = newCoeff;
    //state.bonusMatrix.coeff.splice(state.bonusMatrix.coeff.length, 0, state.bonusMatrix._coeff[data.contractId + "_" + data.axiomaId]);
    state.bonusMatrix.coeff.splice(state.bonusMatrix.coeff.length, 0, newCoeff);
    //state.bonusMatrix.coeff.push(newCoeff);
  }
}
