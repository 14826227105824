<template>
  <div class="_404">
    <div>
      <div style="font-size: 30vh">
        500
      </div>
      <div style="opacity:.7">
        <h2>{{ $t("500. Помилка сервера") }}</h2>
        <h4>{{ info }}</h4>
      </div>

      <el-button type="success" class="go_home_btn" block @click="goHome">{{ $t("Додому") }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error500",
  data() {
    return {
      info: "",
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
._404 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.go_home_btn {
  background: #fff;
  color: #3dba61;
}
</style>
