import { _ } from "vue-underscore";
import { groups } from "../sku/getters";
import Vue from "vue";
import { template } from "./getters";

export function init(state) {
  state.templates = [];
  state.wait = false;
  state.template = null;
  state.orgs = [];
  state.templatesAndRules = [];
  state.waitStatus = null;
  state.tree = [];
}

export function templates_set_wait(state, status) {
  if (typeof status == "string" && status != "") {
    state.wait = true;
    state.waitStatus = status;
  } else {
    state.wait = status;
    state.waitStatus = null;
  }
}

function updateTree(state) {
  state.tree = [];
  state.templates.forEach((template) => {
    state.tree.push(_.extend(template, { parent_id: null, path: [template._id] }));
    template.groups &&
      template.groups.forEach((group) => {
        state.tree.push(_.extend(group, { parent_id: template._id, path: [template._id, group._id] }));
      });
  });
}

// templates mutations
export function templates_load(state, templates) {
  state.templates = templates;
  updateTree(state);
}

export function templates_loadOne(state, template) {
  state.template = template;
  state.group = null;
}

export function templates_create(state, newTemplate) {
  state.templates.push(newTemplate);
  state.template = newTemplate;
  updateTree(state);
}

export function templates_update(state, updatedTemplate) {
  var templateIdx = _.findIndex(state.templates, function(template) {
    return template._id == updatedTemplate._id;
  });
  if (templateIdx >= 0) state.templates[templateIdx] = updatedTemplate;
  updateTree(state);
}

export function templates_delete(state, id) {
  state.templates = state.templates.filter((template) => template._id !== id);
  if (state.template && state.template._id == id) {
    state.template = null;
  }
  updateTree(state);
}

// templates  nested objects mutations (groups)
export function templates_insert_group(state, newGroup) {
  if (state.template) {
    state.template.groups.push(newGroup);
    var tId = state.templates.findIndex((t) => t._id == state.template._id);
    if (tId >= 0) {
      state.templates[tId].groups.push(newGroup);
    }
  }
  updateTree(state);
}

export function templates_update_group(state, updatedTemplateGroup) {
  if (state.template) {
    var groupIdx = _.findIndex(state.template.groups, function(group) {
      return group._id == updatedTemplateGroup._id;
    });
    if (groupIdx >= 0) state.template.groups[groupIdx] = updatedTemplateGroup;
  }
  var templateIdx = state.templates.findIndex((t) => t._id == updatedTemplateGroup.parent_id);
  if (templateIdx >= 0) {
    var groupIdx = _.findIndex(state.templates[templateIdx].groups, function(group) {
      return group._id == updatedTemplateGroup._id;
    });
    if (groupIdx >= 0) state.templates[templateIdx].groups[groupIdx] = updatedTemplateGroup;
  }
  updateSKUCount(state);
  updateTree(state);
}

export function templates_delete_group(state, delIds) {
  if (state.template) {
    state.template.groups = state.template.groups.filter((item) => delIds.indexOf(item._id) == -1);
  }
  state.templates.forEach((t) => {
    t.groups = t.groups.filter((item) => delIds.indexOf(item._id) == -1);
  });
  updateTree(state);
}

function updateSKUCount(state) {
  if (state.template) {
    var tId = state.templates.findIndex((t) => t._id == state.template._id);
    if (tId >= 0) {
      state.templates[tId]._skuCount = state.template.sku.length;
      state.templates[tId].groups.forEach((g) => {
        g._skuCount = state.template.sku.filter((s) => s.groupId == g._id).length;
      });
    }
  }
}

export function templates_insert_sku(state, sku) {
  if (state.template) {
    var existIds = state.template.sku.map((item) => item.drugsId);
    var newSKU = sku.filter((item) => existIds.indexOf(item.drugsId) == -1);
    state.template.sku = state.template.sku.concat(newSKU);
    updateSKUCount(state);
  }
}

export function templates_delete_sku(state, delIds) {
  if (state.template) {
    state.template.sku = state.template.sku.filter((sku) => delIds.indexOf(sku.drugsId) == -1);
    updateSKUCount(state);
  }
}

export function templates_update_sku(state, sku) {
  if (state.template) {
    var skuIdx = state.template.sku.findIndex((s) => s.drugsId == sku.drugsId);
    state.template.sku[skuIdx] = sku;
    updateSKUCount(state);
  }
}
