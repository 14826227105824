import axios from "axios";
import { _ } from "vue-underscore";

export function init(state) {
  state.sku = [];
  state.wait = false;
  state.waitInfo = false;
  state.groups = [];
  state.skuInfo = null;
}

export function sku_update(state, sku) {
  state.sku = sku;
}

export function sku_add(state, sku) {
  var existIds = state.sku.map((item) => item.axiomaId);
  var newIds = sku.filter((item) => existIds.indexOf(item.axiomaId) == -1);
  state.sku = state.sku.concat(sku);
}

export function sku_del(state, sku) {
  var delIds = sku.map((item) => item.drugsId);
  state.sku = state.sku.filter((item) => {
    return delIds.indexOf(item.drugsId) == -1;
  });
}

export function sku_set_wait(state, status) {
  state.wait = status;
}

export function sku_set_wait_info(state, status) {
  state.waitInfo = status;
}

export function sku_update_groups(state, groups) {
  state.groups = groups;
}

export function sku_import_update(state, sku) {
  state.skuImport = sku;
}

export function sku_info_update(state, skuInfo) {
  state.skuInfo = skuInfo;
}
