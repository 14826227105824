import { _ } from "vue-underscore";

export function restrictsStore(state) {
  return state.restricts;
}

function getParentNodeInTree(tree, parentId) {
  if (!tree) return null;
  var result = tree.find((item) => item._id == parentId);
  if (result) return result;
  if (tree.children) return tree.children.find((childItem) => getParentNodeInTree(childItem, parentId));
  else return null;
}

export function restrictsTree(state) {
  return function(name) {
    var result = [{ id: 0, label: name ? name : vm.$i18n.translate("Умови"), children: [] }];
    state.restricts
      .sort((a, b) => {
        if (a && a.parentId) return 1;
        else return -1;
      })
      .forEach((restrict) => {
        if (restrict.parentId) {
          var parentRestrict = getParentNodeInTree(result, restrict.parentId);
          if (parentRestrict) parentRestrict.children.push({ id: restrict._id, label: restrict.name, children: [], parentId: restrict.parentId });
          else result[0].children.push({ id: restrict._id, label: restrict.name, children: [], parentId: 0 });
        } else {
          result[0].children.push({ id: restrict._id, label: restrict.name, children: [], parentId: 0 });
        }
      });
    if (result[0].children.length) return result;
    else return [];
  };
}

export function restrictsStoreFiltered(state) {
  return function(filter) {
    var filterD1 = null;
    var filterD2 = null;
    var filterByName = filter && filter.byName && filter.byName != "" ? filter.byName : null;
    return state.restricts.filter((item) => {
      if (filterByName && item.name.toLowerCase().search(filterByName.toLowerCase()) == -1) {
        return false;
      }
      if (filter && filter.byDateFrom && !filter.byDateTo && Date.parse(item.endDate) < Date.parse(filter.byDateFrom)) {
        return false;
      } else if (filter && filter.byDateTo && !filter.byDateFrom && Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) {
        return false;
      } else if (filter && filter.byDateFrom && filter.byDateTo) {
        if (Date.parse(item.endDate) < Date.parse(filter.byDateFrom) || Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) return false;
      }
      return true;
    });
  };
}

export function wait(state) {
  return function() {
    return state.wait;
  };
}

export function waitStatus(state) {
  return function() {
    if (state.wait) {
      if (state.waitStatus) return state.waitStatus;
      else return vm.$i18n.translate("Будь ласка, зачекайте...");
    } else return "";
  };
}

export function getRestrictById(state) {
  return function(id) {
    return state.restricts.find((item) => {
      return item._id == id;
    });
  };
}

export function getRestrictRuleById(state) {
  return function(id) {
    return state.restrict.rules.find((item) => {
      return item._id == id;
    });
  };
}

export function getRestrictOrgById(state) {
  return function(id) {
    return state.restrict.orgs.find((item) => {
      return item.axiomaId == id;
    });
  };
}

export function getRestrictSKUById(state) {
  return function(id) {
    return state.restrict.sku.find((item) => {
      return item.drugsId == id;
    });
  };
}

export function restrict(state) {
  return function() {
    return state.restrict;
  };
}

export function restrictPlanSettings(state) {
  return function() {
    return state.restrict.planSettings ? state.restrict.planSettings : {};
  };
}

export function restrictsAndRulesFiltered(state) {
  return function(filter) {
    return state.restrictsAndRules.filter((item) => {
      if (filter && item.name.toLowerCase().search(filter.toLowerCase()) == -1) {
        return false;
      }
      return true;
    });
  };
}

export function orgs(state) {
  return function(filterByTemplateId) {
    if (filterByTemplateId) {
      var parentOrgs = [];
      var orgs = state.restrict.orgs.filter((org) => {
        if (org.templateId == filterByTemplateId) {
          parentOrgs = parentOrgs.concat(org.path.map((o) => o[0]));
          return true;
        }
      });
      parentOrgs = _.uniq(parentOrgs).filter((id) => !orgs.find((o) => o.axiomaId == id));
      orgs = orgs.concat(state.restrict.orgs.filter((org) => parentOrgs.indexOf(org.axiomaId) >= 0));
      return orgs;
    } else return state.restrict.orgs;
  };
}

export function orgsWithOKPO(state) {
  return function(filter) {
    var filterByName = filter && filter.byName && filter.byName != "" ? filter.byName.toLowerCase() : null;
    if (filterByName) {
      var parents = [];
      var resId = [];
      var res = state.restrict.orgs.filter((org) => {
        if (!org.okpo || org.name.toLowerCase().search(filterByName) == -1) {
          return false;
        } else {
          parents = parents.concat(org.path.map((org) => org[0]));
          resId.push(org.axiomaId);
          return true;
        }
      });
      parents = _.unique(parents);
      return res.concat(
        state.restrict.orgs.filter((parentOrg) => {
          return parents.indexOf(parentOrg.axiomaId) >= 0 && resId.indexOf(parentOrg.axiomaId) == -1;
        })
      );
    } else {
      return state.restrict.orgs.filter((org) => {
        if (org.okpo) return true;
        else return false;
      });
    }
  };
}

export function restrictSKU(state) {
  return function(filter) {
    var filterByName = filter && filter.byName && filter.byName != "" ? filter.byName.toLowerCase() : null;
    return state.restrict.sku.filter((sku) => {
      if (filterByName && sku.sku.toLowerCase().search(filterByName) == -1) {
        return false;
      } else return true;
    });
  };
}

export function orgsRoots(state) {
  return function() {
    var tmp = [];
    state.restrict.orgs.forEach((org) => {
      tmp.push(org.path[0][0]);
    });
    return state.restrict.orgs.filter((org) => {
      return tmp.indexOf(org.axiomaId) >= 0;
    });
  };
}

function getFirstParentOrgNameWithOKPO(state, childOrg) {
  var pathRevert = childOrg.path
    .slice()
    .reverse()
    .map((obj) => obj[0]);
  var parnetOrgName = "";
  pathRevert.find((id) => {
    var parentOrg = state.restrict.orgs.find((org) => org.axiomaId == id && org.okpo != "");
    if (parentOrg) {
      parnetOrgName = parentOrg.name;
      return true;
    }
  });
  return parnetOrgName;
}

export function orgsByParent(state) {
  return function(parentOrgId) {
    if (parentOrgId) {
      var tmp = state.restrict.orgs.filter((org) => {
        if (parentOrgId.axiomaId != org.axiomaId && !org.okpo)
          return org.path.find((pathItem) => {
            return pathItem == parentOrgId.axiomaId;
          });
      });
      tmp.map((childOrg) => {
        if (!childOrg.orgName) childOrg.orgName = getFirstParentOrgNameWithOKPO(state, childOrg);
      });
      return tmp;
    } else tmp = [];
    return tmp;
  };
}

export function importStore(state) {
  return function() {
    return state.restrictImport;
  };
}

export function importStoreByRestrict(state) {
  return function(filterList) {
    if (!state.restrictImport) {
      return [];
    } else {
      var result = [];
      state.restrictImport
        .filter((restrict) => filterList.indexOf(restrict.restrictId) !== -1)
        .forEach((restrict) => {
          result = result.concat(restrict.sku);
        });
      return result;
    }
  };
}

export function tempSKU(state) {
  return state.tempSKU;
}

export function skuWait(state) {
  return function() {
    return state.skuWait;
  };
}
