import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ua";
import localeEn from "element-ui/lib/locale/lang/en";
import localeRu from "element-ui/lib/locale/lang/ru-RU";
import MyMessage from "@/components/MyMessage";

var currentLocale = localStorage.getItem("locale") || "ua";

Vue.use(ElementUI, { locale: { ua: locale, en: localeEn, ru: localeRu }[currentLocale] });

Vue.prototype.$myMessage = MyMessage;

Vue.config.errorHandler = function(err, vm, info) {
  vm.$alert(
    `<b>${info}: ${err.toString()} </b><br> Stack: ${err.stack
      .toString()
      .split("\n")
      .slice(0, 3)
      .join("<br>")}`,
    {
      title: "Vue Error Handler",
      customClass: "bigAlert",
      dangerouslyUseHTMLString: true,
    }
  );
};

window.onerror = function(msg, url, line, col, error) {
  if (window.vm && window.vm.$alert) window.vm.$alert(`${msg} at url:${url} line ${line}`, "JS Error! ");
  else window.alert(`${msg} at url:${url} line ${line}`, "JS Error! ");
};

window.addEventListener("unhandledrejection", function(event) {
  window.vm.$alert("Unhandled Promise Error!", "Promise Error");
});

window.parseError = (error) => {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("<br> ");
  } else return error;
};

window.parseErrorExt = (err) => {
  var e = err && err.data && err.data.msg ? err.data.msg : err;
  return parseError(e);
};

window.globalErrorMessage = function(msg, err, callback) {
  var e = err && err.data && err.data.msg ? err.data.msg : err;
  var errList = parseError(e);
  if (!callback) {
    window.vm.$message.error({
      dangerouslyUseHTMLString: true,
      message: `${msg} <br> ${errList}`,
      showClose: true,
    });
  } else {
    window.vm.$alert(errList, msg, {
      dangerouslyUseHTMLString: true,
      showClose: true,
      callback: callback,
    });
  }
};

window.showInfo = function(title, info) {
  window.vm.$msgbox({
    title: title,
    message: info,
    showCancelButton: false,
    confirmButtonText: "OK",
    dangerouslyUseHTMLString: true,
    callback: () => {},
  });
};
