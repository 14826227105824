import Vue from "vue";
import { version } from "../../package.json";
import XLSX from "xlsx";
import store from "@/store";

let __globalEnv = {
  info: {
    version: version,
  },
  notify: {},
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function json2csv(data, delimiter) {
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(data[0]);
  const csv = [
    header.join(delimiter ? delimiter : ";"), // header row first
    ...data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(delimiter ? delimiter : ";")),
  ].join("\r\n");
  return csv;
}

const Global = {
  install(Vue, options) {
    Vue.prototype.readJSONFile = function(file) {
      return new Promise((resolve, reject) => {
        var fileReader = new FileReader();
        fileReader.onload = function(e) {
          if (e.loaded == e.total) {
            try {
              var json = JSON.parse(e.target.result);
              resolve(json);
            } catch (err) {
              reject(err);
            }
          }
        };
        fileReader.readAsText(file.raw);
      });
    };
    Vue.prototype.saveDataToJSON = function(data, fileName) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      var blob = new Blob([JSON.stringify(data)], { type: "octet/stream" });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    Vue.prototype.saveDataToXLS = function(data, fileName) {
      // var a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // var blob = new Blob([json2csv(data, ";")], { type: "application/csv;charset=utf-8;" });
      // var url = window.URL.createObjectURL(blob);
      // a.href = url;
      // a.download = fileName;
      // a.click();
      // window.URL.revokeObjectURL(url);
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "List1");
      XLSX.writeFile(wb, fileName);
    };

    Vue.prototype.getInfo = function() {
      return {
        version: __globalEnv.info.version,
      };
    };
    Vue.prototype.fieldValidators = {
      digitalValueValidateRule: (rule, value, callback) => {
        if (value == undefined || value == "") {
          callback();
        } else if ((!isNumeric(value) && !isNumeric(value.split(",").join("."))) || value < 0) {
          callback(new Error(Vue.prototype.$i18n.translate("Поле має бути невід'ємним числом")));
        } else {
          callback();
        }
      },
      defaultValidateRule: { required: true, message: Vue.prototype.$i18n.translate("Це поле є необхідним") },
      checkFileListIsNotEmpty: (rule, value, callback) => {
        if (value && Array.isArray(value) && value.length) {
          callback();
        } else callback(new Error(Vue.prototype.$i18n.translate("Файл має бути обрано")));
      },
    };
    Vue.prototype.checkGridSettingsToChanges = function(component, gridName, savedStateName, columns) {
      function acceptChanges() {
        var obj = {};
        obj[`${savedStateName}AcceptChanges`] = columns.map((col) => ({ colId: col.field, acceptedByUser: true }));
        component.$store.dispatch("main/updateUserSettings", obj);
      }
      var savedState = this.$store.getters["main/userSettings"](`${savedStateName}AcceptChanges`);
      if (savedState) {
        var acceptedFields = savedState.filter((column) => column.acceptedByUser).map((column) => column.colId);
        var allFields = columns.map((column) => column.field);
        var notAcceptedFields = _.difference(allFields, acceptedFields);
        if (notAcceptedFields.length) {
          var notAcceptedColumns = columns.filter((column) => notAcceptedFields.indexOf(column.field) > -1);

          var notify = component.$notify({
            type: "info",
            title: component.$t("Увага!"),
            dangerouslyUseHTMLString: true,
            showClose: true,
            duration: 0,
            message: component.$createElement("div", null, [
              component.$createElement("div", null, [
                component.$createElement("span", {
                  domProps: {
                    innerHTML: `<p>${component.$t("Додано нові стовпчики до таблиці")} "${gridName}":</p><ul><li>${notAcceptedColumns
                      .map((column) => column.headerName)
                      .join("</li><li>")}</li></ul>`,
                  },
                }),
                component.$createElement(
                  "el-button",
                  {
                    on: {
                      click: function(e) {
                        acceptChanges();
                        notify.close();
                      },
                    },
                    props: {
                      size: "small",
                      type: "success",
                    },
                  },
                  [component.$t("Не показувати більше")]
                ),
              ]),
            ]),
          });
        }
      } else {
        acceptChanges();
      }
    };
    Vue.prototype.restoreGridSettings = function(component, gridOptions, gridName, columns, gridNameCaption) {
      var columnDefs = store.getters["main/userSettings"](gridName + "GridColumnDefs");
      if (columnDefs) {
        columns.forEach((col) => {
          _.extend(
            col,
            columnDefs.find((defCol) => defCol.field == col.field)
          );
        });
        gridOptions.api.setColumnDefs(columns);
      }
      var state = store.getters["main/userSettings"](gridName + "GridColumnState");
      if (state) gridOptions.columnApi.setColumnState(state);
      this.checkGridSettingsToChanges(component, gridNameCaption, gridName + "GridColumnState", columns);
    };
    Vue.prototype.saveGridState = function(gridOptions, gridName) {
      var newState = {};
      if (gridOptions && gridOptions.api && gridOptions.api.getColumnDefs) {
        newState[gridName + "GridColumnDefs"] = gridOptions.api.getColumnDefs().map((col) => {
          return { field: col.field, floatingFilter: col.floatingFilter };
        });
        newState[gridName + "GridColumnState"] = gridOptions.columnApi.getColumnState();
        store.dispatch("main/updateUserSettings", newState);
      }
    };
    Vue.prototype.toggleFloatingFilter = function(gridOptions, columns) {
      columns.forEach((columnDef) => {
        columnDef.floatingFilter = !columnDef.floatingFilter;
      });
      var state = gridOptions.columnApi.getColumnState();
      gridOptions.api.setColumnDefs(columns);
      if (state) gridOptions.columnApi.setColumnState(state);
    };
    Vue.prototype.isNumeric = function(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    };
    Vue.prototype.uniqueFilter = function(arr, filterFunc) {
      return arr.filter((value, index, self) => {
        return self.findIndex(filterFunc(value)) === index;
      });
    };
    Vue.prototype.bytesToSize = function(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    };
    Vue.prototype.timestampToLocaleString = function(timestamp) {
      if (timestamp) {
        return new Date(timestamp).toLocaleDateString() + " " + new Date(timestamp).toLocaleTimeString();
      } else return "";
    };
    Vue.prototype.milisecondsToMinSec = function(msec) {
      if (msec) {
        var min = Math.floor(msec / 1000 / 60);
        var sec = Math.floor((msec - min * 1000 * 60) / 1000);
        return `${min}min ${sec}sec`;
      } else return "0 min 0 sec";
    };
  },
};

Vue.use(Global);
