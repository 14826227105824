"use strict";

import Vue from "vue";
import axios from "axios";
import store from "../store";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.VUE_CM_API_URL || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post["Content-Type"] = "application/json";

let config = {
  baseURL: process.env.VUE_APP_CM_API_URL || "", //'http://10.0.2.33:3000'
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 60 * 1000 * 5, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
const _axiosСancel = axios.CancelToken.source();

_axios.interceptors.request.use(
  function(config) {
    let source = axios.CancelToken.source();
    config.cancelToken = source.token;
    store.dispatch("main/addCancelToken", source);
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response && error.response.data) {
      if (error.config.responseType == "blob") {
        return Promise.resolve({ data: { success: false, data: error.response.data }, status: 0 });
      } else if (error.response.status == 401) {
        store.dispatch("main/checkLogin");
      } else if (error.response.status == 500) {
        return Promise.reject({
          data: {
            msg:
              error.message +
              (error.response.data && error.response.data.msg
                ? "<br/>" +
                  (typeof error.response.data.msg == "string"
                    ? error.response.data.msg
                    : error.response.data.msg.msg
                    ? error.response.data.msg.msg
                    : JSON.stringify(error.response.data.msg))
                : ""),
          },
          status: error.response.status,
          request: error,
          message: error.message,
        });
      } else return Promise.reject({ data: error.response.data, status: error.response.status, request: error, message: error.message });
    } else {
      if (error.message && error.message == "cancel")
        //return Promise.reject({ data: { msg: "Request cancelled by user" }, status: 0 });
        return Promise.resolve({ data: { success: true, data: [] }, status: 0 });
      if (error.message && error.message == "quiet") return Promise.reject({ data: { msg: "quiet" }, status: 0 });
      else {
        var data = { data: { msg: error.message }, status: error.response ? error.response.status : 0, message: error.message };
        globalErrorMessage("Network error on request: " + error.config.url, data);
        return Promise.reject(data);
      }
    }
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Vue.axiosСancel = _axiosСancel;
  window.axiosСancel = _axiosСancel;

  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
