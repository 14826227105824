import { _ } from "vue-underscore";

export function contractsStore(state) {
  return state.contracts;
}

export function contractsStoreFiltered(state) {
  return function(filter) {
    var filterD1 = null;
    var filterD2 = null;
    var filterByName = filter && filter.byName && filter.byName != "" ? filter.byName : null;
    return state.contracts.filter((item) => {
      if (filterByName && item.name.toLowerCase().search(filterByName.toLowerCase()) == -1) {
        return false;
      }
      if (filter && filter.byDateFrom && !filter.byDateTo && Date.parse(item.endDate) < Date.parse(filter.byDateFrom)) {
        return false;
      } else if (filter && filter.byDateTo && !filter.byDateFrom && Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) {
        return false;
      } else if (filter && filter.byDateFrom && filter.byDateTo) {
        if (Date.parse(item.endDate) < Date.parse(filter.byDateFrom) || Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) return false;
      }
      return true;
    });
  };
}

export function contractsTotalCount(state) {
  return state.contracts ? state.contracts.length : 0;
}

export function wait(state) {
  return function() {
    return state.wait;
  };
}

export function getContractById(state) {
  return function(id) {
    if (state.contracts)
      return state.contracts.find((item) => {
        return item._id == id;
      });
    else return null;
  };
}

export function contract(state) {
  return function() {
    return state.contract;
  };
}

export function contractSKUDictionary(state) {
  return function() {
    if (state.contract) return state.contract.sku;
    else return [];
  };
}

export function contractStructures(state) {
  return function() {
    if (state.contract) return state.contract.structures;
    else return [];
  };
}

export function contractStructuresWithParents(state) {
  return function(filter) {
    if (state.contract) {
      var filterByName = filter && filter != "" ? filter.toLowerCase() : null;
      var parents = [];
      var resId = [];
      var res = state.contract.structures.filter((org) => {
        if (filterByName && org.name.toLowerCase().search(filterByName) == -1) {
          return false;
        } else {
          parents = parents.concat(org.path.map((org) => org[0]));
          resId.push(org.axiomaId);
          return true;
        }
      });
      parents = _.unique(parents);
      return res.concat(
        state.contract.structures.filter((parentOrg) => {
          return parents.indexOf(parentOrg.axiomaId) >= 0 && resId.indexOf(parentOrg.axiomaId) == -1;
        })
      );
    } else return [];
  };
}

export function getParentOrgs(state) {
  return function(orgIds) {
    if (state.contract) {
      var parents = [];
      var res = state.contract.structures.filter((org) => {
        {
          if (orgIds.indexOf(org.axiomaId) >= 0) parents = parents.concat(org.path.map((org) => org[0]));
          return true;
        }
      });
      parents = _.unique(parents);
      return state.contract.structures.filter((o) => parents.indexOf(o.axiomaId) >= 0 && orgIds.indexOf(o.axiomaId) == -1);
    } else return [];
  };
}

export function getFilterByDateFrom(state) {
  return function() {
    return state.filterByDateFrom;
  };
}

export function getFilterByDateTo(state) {
  return function() {
    return state.filterByDateTo;
  };
}
