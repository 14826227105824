import { _ } from "vue-underscore";

export function init(context) {
  context.commit("init");
}

export function load({ commit }, { id }) {
  return new Promise((resolve, reject) => {
    commit("restricts_load", []);
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/list",
      data: { contractId: id },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_load", resp.data.data || []);
          resolve(resp);
        } else {
          commit("restricts_load", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("restricts_load", []);
        reject(err);
      });
  });
}

export function loadOne({ commit }, { id, id2 }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    commit("main/update_global_loading", "Завантаження умови...", { root: true });
    axios({
      url: "/api/restrict/get",
      data: { contractId: id, restrictId: id2 },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_loadOne", resp.data.data || []);
          resolve(resp);
        } else {
          commit("restricts_loadOne", null);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("restricts_loadOne", null);
        reject(err);
      });
  });
}

export function loadRestrictsAndRules({ commit }, { id }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/restrictsAndRules/list",
      data: { contractId: id },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_and_rules_load", resp.data.data || []);
          resolve(resp);
        } else {
          commit("restricts_and_rules_load", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("restricts_and_rules_load", []);
        reject(err);
      });
  });
}

export function create({ commit }, newRestrict) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/create",
      data: newRestrict,
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_create", resp.data.data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function copy({ commit }, copyData) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/copy",
      data: copyData,
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_create", resp.data.data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function update({ commit }, updateRestrict) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/update",
      data: updateRestrict,
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_update", updateRestrict.data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function insertRule({ commit }, { contractId, restrictId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/insertRule",
      data: { contractId, restrictId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_insert_rule", resp.data.data);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateRule({ commit }, { contractId, restrictId, ruleId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/updateRule",
      data: { contractId, restrictId, ruleId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_update_rule", data);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteRule({ dispatch, commit, getters }, { contractId, restrictId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/deleteRule",
      data: { contractId, restrictId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          var planSettingsOld = JSON.parse(JSON.stringify(getters.restrict().planSettings));
          commit("restricts_delete_rule", data || []);
          var planSettingsNew = getters.restrict().planSettings;
          if (planSettingsOld && planSettingsOld.ruleId && planSettingsNew && planSettingsOld.ruleId != planSettingsNew.ruleId) {
            // Update Restruct
            update({ commit }, { contractId, restrictId, data: getters.restrict() }).then((res) => {
              resolve(resp.data);
            });
          } else {
            resolve(resp.data);
          }
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function insertOrg({ commit }, { contractId, restrictId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/insertOrg",
      data: { contractId, restrictId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_insert_org", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateOrg({ commit }, { contractId, restrictId, axiomaId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/updateOrg",
      data: { contractId, restrictId, axiomaId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_update_org", data);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteOrg({ commit }, { contractId, restrictId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/deleteOrg",
      data: { contractId, restrictId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_delete_org", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function replaceOrgs({ commit }, { contractId, restrictId, newOrgs }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/replaceOrgs",
      data: { contractId, restrictId, newOrgs },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_replace_orgs", newOrgs);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function insertSKU({ commit }, { contractId, restrictId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/insertSKU",
      data: { contractId, restrictId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_insert_sku", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSKU({ commit }, { contractId, restrictId, drugsId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/updateSKU",
      data: { contractId, restrictId, drugsId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_update_sku", data);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function replaceSKU({ commit }, { contractId, restrictId, newSKU }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/replaceSKU",
      data: { contractId, restrictId, newSKU },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_replace_sku", resp.data);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteSKU({ commit }, { contractId, restrictId, data }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/deleteSKU",
      data: { contractId, restrictId, data },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_delete_sku", data || []);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function del({ commit }, deletedRestrictId) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    axios({
      url: "/api/restrict/delete",
      data: deletedRestrictId,
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_delete", deletedRestrictId.restrictId);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updatePlanSettings(context, updateRestrict) {
  var restrictSrc = context.getters["restrict"]();
  restrictSrc.planSettings = updateRestrict.data;
  updateRestrict.data = restrictSrc;
  return new Promise((resolve, reject) => {
    update(context, updateRestrict)
      .then(() => {
        context.commit("restricts_loadOne", restrictSrc);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getPlanSumByOrgsList(orgs) {
  var result = 0;
  _.each(orgs, (org) => {
    if (!isNaN(org.plan) && Number(org.plan) > 0) result = result + Number(org.plan);
  });
  return result;
}

function getPlanSumByOrgsListRecursive(orgs, parentOrg) {
  var result = 0;
  var childrens = orgs.filter((org) => org.parent_id == parentOrg.axiomaId);
  _.each(childrens, (childrenOrg) => {
    result = result + getPlanSumByOrgsListRecursive(orgs, childrenOrg);
    if (!isNaN(childrenOrg.plan) && Number(childrenOrg.plan) > 0 && !childrenOrg.okpo) {
      result = result + Number(childrenOrg.plan);
    }
  });
  return result;
}

function getTurnoverSumByOrgsList(orgs) {
  var turnover = 0;
  _.each(orgs, (org) => {
    if (!isNaN(org.turnover) && Number(org.turnover) > 0) turnover = turnover + Number(org.turnover);
  });
  return turnover;
}

function getSharesByOrgs(orgs, total) {
  var result = {};
  _.each(orgs, (org, idx, orgsArray) => {
    var share = 1;
    if (total > 0) share = (org.turnover ? org.turnover : 0) / total;
    else share = 1 / orgsArray.length;
    result[org.axiomaId] = share;
  });
  return result;
}

// export function calcPlan1(context) {
//   context.commit("main/set_global_loading", "Розподіл плану...", { root: true });
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       try {
//         var restrictSrc = context.getters["restrict"]();
//         if (!restrictSrc.planSettings) reject("Не задано налаштувань для умови!");
//         if (!restrictSrc.orgs || restrictSrc.orgs.length == 0) reject("Не не обрано структур для плану!");
//         var totalPlanRule = restrictSrc.rules.find((rule) => rule._id == restrictSrc.planSettings.ruleId);
//         if (!totalPlanRule || !totalPlanRule.plan) reject("Не знайдено базового плану!");
//         var totalPlan = totalPlanRule.plan * (1 + restrictSrc.planSettings.planGRPercent / 100);
//         var orgs = context.getters["orgs"]();
//         // Clear not fixed plan
//         _.each(orgs, (org, idx) => {
//           if (!org.planFixed) context.commit("restricts_update_org", _.extend(org, { plan: null, planFixed: undefined }));
//         });
//         // get Calc plan for each root orgs
//         var rootOrgs = orgs.filter((org) => org.parent_id == 0);
//         // rootOrgs.forEach((rootOrg)=>{

//         // });
//         var fixedPlanForPoints1 = _.reduce(rootOrgs, (prev, curr) => Number(prev) + Number(aggregateFixedChildrenOKPO(orgs, curr)), 0);
//         var fixedPlanForPoints2 = _.reduce(rootOrgs, (prev, curr) => Number(prev) + Number(aggregateFixedChildrenNotOKPO(orgs, curr)), 0);
//         var fixedPlanForPoints = fixedPlanForPoints1 + fixedPlanForPoints2;

//         var groups = {};
//         var aggregateGroup = [];
//         var skipAggrGroup = [];

//         //
//         // Fill Groups
//         //
//         _.each(
//           orgs.filter((org) => org.plan == null),
//           (org, idx) => {
//             var childrensCount = orgs.filter((childOrg) => childOrg.parent_id == org.axiomaId).length;
//             if (org.okpo && (childrensCount > 0 || !org.planFixed)) {
//               aggregateGroup.push(org);
//             } else {
//               var parentOrgWithFixedPlan = getFixedParentOrg(orgs, org);
//               if (parentOrgWithFixedPlan) {
//                 if (!groups[parentOrgWithFixedPlan.axiomaId]) {
//                   groups[parentOrgWithFixedPlan.axiomaId] = [org.axiomaId];
//                 } else {
//                   if (groups[parentOrgWithFixedPlan.axiomaId].indexOf(org.axiomaId) == -1) groups[parentOrgWithFixedPlan.axiomaId].push(org.axiomaId);
//                 }
//               } else {
//                 if (!groups["global"]) {
//                   groups["global"] = [org.axiomaId];
//                 } else {
//                   if (groups["global"].indexOf(org.axiomaId) == -1) groups["global"].push(org.axiomaId);
//                 }
//               }
//             }
//           }
//         );

//         //
//         // Distribute plan
//         //
//         switch (restrictSrc.planSettings.planAlgType) {
//           case "shareEqually": {
//             // Calc plan for groups (Equally)
//             _.each(groups, (group, idx) => {
//               var orgsInGroup = orgs.filter((o) => group.indexOf(o.axiomaId) >= 0);
//               var deltaBetweenPlanForDistributeAndCalcPlan = 0;
//               if (idx == "global") {
//                 deltaBetweenPlanForDistributeAndCalcPlan = totalPlan - fixedPlanForPoints;
//               } else {
//                 var parentForFixedGroup = orgs.find((o) => o.axiomaId == idx);
//                 deltaBetweenPlanForDistributeAndCalcPlan = parentForFixedGroup.plan;
//               }
//               var planByOrg = Math.floor(deltaBetweenPlanForDistributeAndCalcPlan / group.length);
//               // Distribute main part
//               _.each(orgsInGroup, (org) => {
//                 context.commit("restricts_update_org", _.extend(org, { plan: org.plan + planByOrg }));
//               });
//               // Distribute rounded part
//               deltaBetweenPlanForDistributeAndCalcPlan = getDelta(orgsInGroup, deltaBetweenPlanForDistributeAndCalcPlan);
//               while (deltaBetweenPlanForDistributeAndCalcPlan > 1) {
//                 _.each(orgsInGroup, (org) => {
//                   context.commit("restricts_update_org", _.extend(org, { plan: org.plan + Math.sign(deltaBetweenPlanForDistributeAndCalcPlan) }));
//                   deltaBetweenPlanForDistributeAndCalcPlan -= Math.sign(deltaBetweenPlanForDistributeAndCalcPlan);
//                 });
//               }
//             });
//             break;
//           }
//           case "shareFixed": {
//             // Calc plan for groups (Fixed)
//             _.each(groups, (group, idx) => {
//               if (idx == "global") {
//                 var planByOrg = totalPlan;
//               } else {
//                 var parentForFixedGroup = orgs.find((o) => o.axiomaId == idx);
//                 var planByOrg = totalPlan;
//               }
//               _.each(group, (orgId) => {
//                 var org = orgs.find((o) => o.axiomaId == orgId);
//                 context.commit("restricts_update_org", _.extend(org, { plan: planByOrg }));
//               });
//             });
//             break;
//           }
//           case "shareByTurnover": {
//             // Calc plan for groups (By turnover)
//             var turnover = context.rootGetters["orgs/turnover"];
//             _.each(groups, (group, idx) => {
//               //
//               // Fill turnover
//               //
//               var orgsGroupWithTurnover = [];
//               // Select turnover type
//               var turnoverType = { last3Months: 3, last6Months: 6, last12Months: 12 }[restrictSrc.planSettings.turnoverPeriodType];
//               _.each(group, (org) => {
//                 orgsGroupWithTurnover.push({ axiomaId: org, turnover: turnover[org] ? turnover[org][turnoverType] : 0 });
//               });
//               // Get total turnover
//               var totalTurnover = getTurnoverSumByOrgsList(orgsGroupWithTurnover);
//               // Get shares
//               var shares = getSharesByOrgs(orgsGroupWithTurnover, totalTurnover);
//               var planForDistribute = 0;
//               if (idx == "global") {
//                 planForDistribute = totalPlan - fixedPlanForPoints;
//               } else {
//                 var parentForFixedGroup = orgs.find((o) => o.axiomaId == idx);
//                 planForDistribute = parentForFixedGroup.plan;
//               }

//               var orgsInGroup = orgs.filter((o) => group.indexOf(o.axiomaId) >= 0);
//               // Distribute main part of plan
//               var deltaBetweenPlanForDistributeAndCalcPlan = getDelta(orgsInGroup, planForDistribute);
//               _.each(orgsInGroup, (org) => {
//                 var deltaPlan = Math.floor(deltaBetweenPlanForDistributeAndCalcPlan * shares[org.axiomaId]);
//                 context.commit("restricts_update_org", _.extend(org, { plan: org.plan + deltaPlan }));
//               });
//               // Distribute rounded part
//               deltaBetweenPlanForDistributeAndCalcPlan = getDelta(orgsInGroup, planForDistribute);
//               while (deltaBetweenPlanForDistributeAndCalcPlan > 1) {
//                 _.each(orgsInGroup, (org) => {
//                   context.commit("restricts_update_org", _.extend(org, { plan: org.plan + Math.sign(deltaBetweenPlanForDistributeAndCalcPlan) }));
//                   deltaBetweenPlanForDistributeAndCalcPlan -= Math.sign(deltaBetweenPlanForDistributeAndCalcPlan);
//                 });
//               }
//             });
//             break;
//           }
//         }

//         //
//         // Aggregate
//         //
//         aggregateGroup.forEach((aggregateGroupItem) => aggregateChildren(context, orgs, aggregateGroupItem));

//         //
//         // Save Calc Plan
//         //
//         replaceOrgs(context, { contractId: context.rootGetters["contracts/contract"]()._id, restrictId: restrictSrc._id, newOrgs: orgs })
//           .then(() => {
//             resolve("План перераховано!");
//           })
//           .catch((err) => {
//             reject(err);
//           });
//       } catch (err) {
//         reject(err);
//       } finally {
//         context.commit("main/set_global_loading", "", { root: true });
//       }
//     }, 50);
//   });
// }

export function calcPlan(context) {
  context.commit("main/set_global_loading", "Розподіл плану...", { root: true });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        var restrictSrc = context.getters["restrict"]();
        if (!restrictSrc.planSettings) reject("Не задано налаштувань для умови!");
        if (!restrictSrc.orgs || restrictSrc.orgs.length == 0) reject("Не не обрано структур для плану!");
        var totalPlanRule = restrictSrc.rules.find((rule) => rule._id == restrictSrc.planSettings.ruleId);
        if (!totalPlanRule || !totalPlanRule.plan) reject("Не знайдено базового плану!");
        var totalPlan = totalPlanRule.plan * (1 + restrictSrc.planSettings.planGRPercent / 100);
        var orgs = context.getters["orgs"]();
        // Clear not fixed plan
        _.each(orgs, (org, idx) => {
          if (!org.planFixed) context.commit("restricts_update_org", _.extend(org, { plan: null, planFixed: undefined }));
        });
        // get Calc plan for each root orgs
        var fixedPlanForPoints = 0;
        var rootOrgs = orgs.filter((org) => org.parent_id == 0);
        rootOrgs.forEach((rootOrg) => {
          var fixedPlanForPoints1 = aggregateFixedChildrenOKPO(orgs, rootOrg);
          var fixedPlanForPoints2 = aggregateFixedChildrenNotOKPO(orgs, rootOrg);
          fixedPlanForPoints =
            fixedPlanForPoints + (Number.isNaN(fixedPlanForPoints1) ? 0 : fixedPlanForPoints1) + (Number.isNaN(fixedPlanForPoints2) ? 0 : fixedPlanForPoints2);
        });
        if (rootOrgs.length > 1) {
          context.commit("restricts_insert_org", [{ axiomaId: 0, name: "FakeOrg", plan: null, planFixed: undefined, parent_id: -1, path: [-1], okpo: "-1" }]);
        }

        var groups = {};
        var aggregateGroup = [];
        var skipAggrGroup = [];

        //
        // Fill Groups
        //
        _.each(
          orgs.filter((org) => org.plan == null),
          (org, idx) => {
            var childrensCount = orgs.filter((childOrg) => childOrg.parent_id == org.axiomaId).length;
            if (org.okpo && (childrensCount > 0 || !org.planFixed)) {
              aggregateGroup.push(org);
            } else {
              var parentOrgWithFixedPlan = getFixedParentOrg(orgs, org);
              if (parentOrgWithFixedPlan) {
                if (!groups[parentOrgWithFixedPlan.axiomaId]) {
                  groups[parentOrgWithFixedPlan.axiomaId] = [org.axiomaId];
                } else {
                  if (groups[parentOrgWithFixedPlan.axiomaId].indexOf(org.axiomaId) == -1) groups[parentOrgWithFixedPlan.axiomaId].push(org.axiomaId);
                }
              } else {
                if (!groups["global"]) {
                  groups["global"] = [org.axiomaId];
                } else {
                  if (groups["global"].indexOf(org.axiomaId) == -1) groups["global"].push(org.axiomaId);
                }
              }
            }
          }
        );

        //
        // Distribute plan
        //
        switch (restrictSrc.planSettings.planAlgType) {
          case "shareEqually": {
            // Calc plan for groups (Equally)
            _.each(groups, (group, idx) => {
              var orgsInGroup = orgs.filter((o) => group.indexOf(o.axiomaId) >= 0);
              var deltaBetweenPlanForDistributeAndCalcPlan = 0;
              if (idx == "global") {
                deltaBetweenPlanForDistributeAndCalcPlan = totalPlan - fixedPlanForPoints;
              } else {
                var parentForFixedGroup = orgs.find((o) => o.axiomaId == idx);
                deltaBetweenPlanForDistributeAndCalcPlan = parentForFixedGroup.plan;
              }
              var planByOrg = Math.floor(deltaBetweenPlanForDistributeAndCalcPlan / group.length);
              // Distribute main part
              _.each(orgsInGroup, (org) => {
                context.commit("restricts_update_org", _.extend(org, { plan: org.plan + planByOrg }));
              });
              // Distribute rounded part
              deltaBetweenPlanForDistributeAndCalcPlan = getDelta(orgsInGroup, deltaBetweenPlanForDistributeAndCalcPlan);
              while (deltaBetweenPlanForDistributeAndCalcPlan > 1) {
                _.each(orgsInGroup, (org) => {
                  context.commit("restricts_update_org", _.extend(org, { plan: org.plan + Math.sign(deltaBetweenPlanForDistributeAndCalcPlan) }));
                  deltaBetweenPlanForDistributeAndCalcPlan -= Math.sign(deltaBetweenPlanForDistributeAndCalcPlan);
                });
              }
            });
            break;
          }
          case "shareFixed": {
            // Calc plan for groups (Fixed)
            _.each(groups, (group, idx) => {
              if (idx == "global") {
                var planByOrg = totalPlan;
              } else {
                var parentForFixedGroup = orgs.find((o) => o.axiomaId == idx);
                var planByOrg = totalPlan;
              }
              _.each(group, (orgId) => {
                var org = orgs.find((o) => o.axiomaId == orgId);
                context.commit("restricts_update_org", _.extend(org, { plan: planByOrg }));
              });
            });
            break;
          }
          case "shareByTurnover": {
            // Calc plan for groups (By turnover)
            var turnover = context.rootGetters["orgs/turnover"];
            _.each(groups, (group, idx) => {
              //
              // Fill turnover
              //
              var orgsGroupWithTurnover = [];
              // Select turnover type
              var turnoverType = { last3Months: 3, last6Months: 6, last12Months: 12 }[restrictSrc.planSettings.turnoverPeriodType];
              _.each(group, (org) => {
                orgsGroupWithTurnover.push({ axiomaId: org, turnover: turnover[org] ? turnover[org][turnoverType] : 0 });
              });
              // Get total turnover
              var totalTurnover = getTurnoverSumByOrgsList(orgsGroupWithTurnover);
              // Get shares
              var shares = getSharesByOrgs(orgsGroupWithTurnover, totalTurnover);
              var planForDistribute = 0;
              if (idx == "global") {
                planForDistribute = totalPlan - fixedPlanForPoints;
              } else {
                var parentForFixedGroup = orgs.find((o) => o.axiomaId == idx);
                planForDistribute = parentForFixedGroup.plan;
              }

              var orgsInGroup = orgs.filter((o) => group.indexOf(o.axiomaId) >= 0);
              // Distribute main part of plan
              var deltaBetweenPlanForDistributeAndCalcPlan = getDelta(orgsInGroup, planForDistribute);
              _.each(orgsInGroup, (org) => {
                var deltaPlan = Math.floor(deltaBetweenPlanForDistributeAndCalcPlan * shares[org.axiomaId]);
                context.commit("restricts_update_org", _.extend(org, { plan: org.plan + deltaPlan }));
              });
              // Distribute rounded part
              deltaBetweenPlanForDistributeAndCalcPlan = getDelta(orgsInGroup, planForDistribute);
              while (deltaBetweenPlanForDistributeAndCalcPlan > 1) {
                _.each(orgsInGroup, (org) => {
                  context.commit("restricts_update_org", _.extend(org, { plan: org.plan + Math.sign(deltaBetweenPlanForDistributeAndCalcPlan) }));
                  deltaBetweenPlanForDistributeAndCalcPlan -= Math.sign(deltaBetweenPlanForDistributeAndCalcPlan);
                });
              }
            });
            break;
          }
        }

        //
        // Aggregate
        //
        aggregateGroup.forEach((aggregateGroupItem) => aggregateChildren(context, orgs, aggregateGroupItem));

        //
        // Save Calc Plan
        //
        context.commit("restricts_delete_org", [{ axiomaId: 0 }]);
        replaceOrgs(context, { contractId: context.rootGetters["contracts/contract"]()._id, restrictId: restrictSrc._id, newOrgs: orgs })
          .then(() => {
            resolve("План перераховано!");
          })
          .catch((err) => {
            reject(err);
          });
      } catch (err) {
        reject(err);
      } finally {
        context.commit("restricts_delete_org", [{ axiomaId: 0 }]);
        context.commit("main/set_global_loading", "", { root: true });
      }
    }, 50);
  });
}

function getFixedParentOrg(orgs, org) {
  if (org) {
    var parentOrg = orgs.find((pOrg) => pOrg.axiomaId == org.parent_id);
    if (parentOrg && parentOrg.planFixed) return parentOrg;
    else return getFixedParentOrg(orgs, parentOrg);
  } else return null;
}

function aggregateChildren(context, orgs, parentOrg) {
  if (parentOrg.plan !== null) return;
  var childrens = orgs.filter((childOrg) => childOrg.parent_id == parentOrg.axiomaId);
  var notCalcChildrens = childrens.filter((childOrg) => childOrg.plan == null);
  if (notCalcChildrens && notCalcChildrens.length) {
    notCalcChildrens.forEach((notCalcChildren) => aggregateChildren(context, orgs, notCalcChildren));
  }
  var aggrPlan = getPlanSumByOrgsListRecursive(orgs, parentOrg);
  context.commit("restricts_update_org", _.extend(parentOrg, { plan: aggrPlan }));
}

function aggregateFixedChildrenOKPO(orgs, parentOrg) {
  if (!parentOrg) return 0;
  if (parentOrg.plan !== null && parentOrg.okpo) return Number(parentOrg.plan);
  var childrens = orgs.filter((childOrg) => childOrg.parent_id == parentOrg.axiomaId);
  return _.reduce(childrens, (prev, curr, idx) => prev + aggregateFixedChildrenOKPO(orgs, curr), 0);
}

function aggregateFixedChildrenNotOKPO(orgs, org) {
  if (!org) return 0;
  var parentOrgWithFixedPlan = getFixedParentOrg(orgs, org);
  if (parentOrgWithFixedPlan) return 0;
  if (org.plan !== null && !org.okpo) return Number(org.plan);
  var childrens = orgs.filter((childOrg) => childOrg.parent_id == org.axiomaId);
  return _.reduce(childrens, (prev, curr, idx) => prev + aggregateFixedChildrenNotOKPO(orgs, curr), 0);
}

function getDelta(orgs, totalPlan) {
  var calculatedPlan = getPlanSumByOrgsList(
    orgs.filter((org) => {
      if (!org.okpo) return true;
      if (org.planFixed) {
        var childrensCount = orgs.filter((childOrg) => childOrg.parent_id == org.axiomaId).length;
        if (childrensCount == 0 && org.planFixed) return true;
      } else return false;
    })
  );
  var delta = totalPlan - calculatedPlan;
  return delta;
}

function fillTurnoverForOrgsList(orgs, turnover) {
  _.each(orgs, (org) => {
    org.turnover = turnover[org.axiomaId];
  });
}

export function clearPlan(context) {
  return new Promise((resolve, reject) => {
    var orgs = context.getters["orgs"]();
    _.each(orgs, (org, idx) => {
      context.commit("restricts_update_org", _.extend(org, { plan: null, planFixed: null }));
    });
    var restrictSrc = context.getters["restrict"]();
    replaceOrgs(context, { contractId: context.rootGetters["contracts/contract"]()._id, restrictId: restrictSrc._id, newOrgs: orgs })
      .then(() => {
        resolve("План зкинуто!");
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function importFromUploadedFileXLS({ commit }, { fileName, params }) {
  return new Promise((resolve, reject) => {
    commit("restricts_set_wait", true);
    commit("restricts_import_update", []);
    axios({
      url: "/api/restrict/importFromXLS",
      data: { fileName, ...params },
      method: "POST",
    })
      .finally(() => {
        commit("restricts_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restricts_import_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("restricts_import_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("restricts_import_update", []);
        reject(err);
      });
  });
}

export function setWaitStatus({ commit }, waitStatus) {
  commit("restricts_set_wait", waitStatus);
}

export function deleteFromImport({ commit }, delIds) {
  commit("restricts_del_import", delIds);
}

export function deleteFromImportSKU({ commit }, delIds) {
  commit("restricts_del_import_sku", delIds);
}

export function getSKUListByRestrict({ commit }, { contractId, restrictId }) {
  commit("restrict_sku_update", []);
  return new Promise((resolve, reject) => {
    commit("restrict_sku_set_wait", true);
    axios({
      url: "/api/restricts/get/skuListByRestrict",
      data: { contractId, restrictId },
      method: "POST",
    })
      .finally(() => {
        commit("restrict_sku_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("restrict_sku_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("restrict_sku_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("restrict_sku_update", []);
        reject(err);
      });
  });
}

export function clearTempSKU({ commit }) {
  commit("restrict_sku_update", []);
}
