import { _ } from "vue-underscore";

export function templatessStore(state) {
  return state.templates;
}

export function templatesTree(state) {
  return function() {
    return state.tree;
  };
}

export function templatesStoreFiltered(state) {
  return function(filter) {
    if (filter) {
      filter = filter.toLowerCase();
      return state.templates.filter((t) => t.name.toLowerCase().search(filter));
    }
    return state.templates;
  };
}

export function wait(state) {
  return function() {
    return state.wait;
  };
}

export function waitStatus(state) {
  return function() {
    if (state.wait) {
      if (state.waitStatus) return state.waitStatus;
      else return vm.$i18n.translate("Будь ласка, зачекайте...");
    } else return "";
  };
}

export function getTemplateById(state) {
  return function(id) {
    return state.templates.find((item) => {
      return item._id == id;
    });
  };
}

export function template(state) {
  return function() {
    return state.template;
  };
}

export function getGroupById(state) {
  return function(id) {
    if (state.template) {
      return state.template.groups.find((item) => {
        return item._id == id;
      });
    } else {
      if (state.templates) {
        var group = null;
        state.templates.find((t) => {
          group = t.groups.find((g) => g._id == id);
          return !!group;
        });
        return group;
      }
    }
    return null;
  };
}

export function getSKUCountByTemplateIdAndGroupId(state) {
  return function(templateId, groupId) {
    if (state.templates) {
      var t = state.templates.find((t) => t._id == templateId);
      if (t && t.sku) {
        return t.sku.filter((s) => s.groupId == groupId).length;
      }
      return 0;
    }
    return 0;
  };
}

export function sku(state) {
  return function(groupId) {
    return state.template && state.template.sku && Array.isArray(state.template.sku)
      ? state.template.sku.filter((sku) => !groupId || sku.groupId == groupId)
      : [];
  };
}

export function templateGroups(state) {
  return function() {
    if (state.template && state.template.groups) {
      return state.template.groups.map((group) => {
        return { label: group.name, value: group._id };
      });
    }
    return [];
  };
}
