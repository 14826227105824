import { _ } from "vue-underscore";

export function init(state) {
  state.restricts = [];
  state.wait = false;
  state.restrict = null;
  state.orgs = [];
  state.restrictsAndRules = [];
  state.waitStatus = null;
  state.tempSKU = [];
}

export function restricts_set_wait(state, status) {
  if (typeof status == "string" && status != "") {
    state.wait = true;
    state.waitStatus = status;
  } else {
    state.wait = status;
    state.waitStatus = null;
  }
}

// restricts mutations
export function restricts_load(state, restricts) {
  state.restricts = restricts;
}

export function restricts_loadOne(state, restrict) {
  state.restrict = restrict;
}

export function restricts_create(state, newRestrict) {
  state.restricts.push(newRestrict);
  state.restrict = newRestrict;
}

export function restricts_update(state, updatedRestrict) {
  var restrictIdx = _.findIndex(state.restricts, function(restrict) {
    return restrict._id == updatedRestrict._id;
  });
  if (restrictIdx >= 0) state.restricts[restrictIdx] = updatedRestrict;
}

export function restricts_delete(state, id) {
  state.restricts = state.restricts.filter((restrict) => restrict._id !== id);
}

// restricts  nested objects mutations (SKU)
export function restricts_insert_sku(state, sku) {
  var existIds = state.restrict.sku.map((item) => item.drugsId);
  var newSKU = sku.filter((item) => existIds.indexOf(item.drugsId) == -1);
  state.restrict.sku = state.restrict.sku.concat(newSKU);
}

export function restricts_update_sku(state, updatedRestrictSKU) {
  var skuIdx = _.findIndex(state.restrict.sku, function(sku) {
    return sku.drugsId == updatedRestrictSKU.drugsId;
  });
  if (skuIdx >= 0) state.restrict.sku[skuIdx] = updatedRestrictSKU;
}

export function restricts_replace_sku(state, newSKU) {
  state.restrict.sku = newSKU;
}

export function restricts_delete_sku(state, delIds) {
  state.restrict.sku = state.restrict.sku.filter((item) => delIds.indexOf(item.drugsId) == -1);
}

// restricts  nested objects mutations (ORGs)
export function restricts_insert_org(state, orgs) {
  var existIds = state.restrict.orgs.map((item) => item.axiomaId);
  var newOrgs = orgs.filter((item) => existIds.indexOf(item.axiomaId) == -1);
  state.restrict.orgs = state.restrict.orgs.concat(newOrgs);
}

export function restricts_update_org(state, updatedRestrictOrg) {
  // var orgIdx = _.findIndex(state.restrict.orgs, function(org) {
  //   return org.axiomaId == updatedRestrictOrg.axiomaId;
  // });
  // if (orgIdx >= 0) state.restrict.orgs[orgIdx] = updatedRestrictOrg;

  state.restrict.orgs = state.restrict.orgs.map((org) => {
    if (org.axiomaId == updatedRestrictOrg.axiomaId) {
      org = updatedRestrictOrg;
    }
    return org;
  });
}

export function restricts_update_org_unset_template(state, templateId) {
  state.restrict.orgs = state.restrict.orgs.map((org) => {
    if (org.templateId == templateId) org.templateId = null;
    return org;
  });
}

export function restricts_replace_orgs(state, newOrgs) {
  state.restrict.orgs = newOrgs;
}

export function restricts_delete_org(state, delIds) {
  state.restrict.orgs = state.restrict.orgs.filter((item) => delIds.indexOf(item.axiomaId) == -1);
}

// restricts  nested objects mutations (rules)
export function restricts_insert_rule(state, newRule) {
  state.restrict.rules.push(newRule);
}

export function restricts_update_rule(state, updatedRestrictRule) {
  var ruleIdx = _.findIndex(state.restrict.rules, function(rule) {
    return rule._id == updatedRestrictRule._id;
  });
  if (ruleIdx >= 0) state.restrict.rules[ruleIdx] = updatedRestrictRule;
}

export function restricts_delete_rule(state, delIds) {
  var restrictId = state.restrict._id;
  state.restrict.rules = state.restrict.rules
    .filter((item) => delIds.indexOf(item._id) == -1)
    .map((item) => {
      item.parentRules = item.parentRules.filter((rule) => {
        return delIds.find((delId) => {
          return `${restrictId}:${delId}` != rule;
        });
      });
      return item;
    });
  if (state.restrict.planSettings && state.restrict.planSettings.ruleId && delIds.indexOf(state.restrict.planSettings.ruleId) >= 0) {
    state.restrict.planSettings.ruleId = null;
  }
  return true;
}

// Restricts and Rules mutations
export function restricts_and_rules_load(state, restrictsAndRules) {
  state.restrictsAndRules = restrictsAndRules;
}

export function restricts_import_update(state, restricts) {
  state.restrictImport = restricts;
}

export function restricts_del_import_sku(state, delIds) {
  state.restrictImport.forEach((item) => {
    item.sku = item.sku.filter((sku) => delIds.indexOf(sku.importId) == -1);
    item.resonsedSKUCount = item.sku.filter((item) => item.drugsId).length;
  });
}

export function restricts_del_import(state, delIds) {
  state.restrictImport = state.restrictImport.filter((item) => delIds.indexOf(item.restrictId) == -1);
}

export function restrict_sku_set_wait(state, wait) {
  state.skuWait = wait;
}

export function restrict_sku_update(state, sku) {
  state.tempSKU = sku;
}
