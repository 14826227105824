import axios from "axios";
import { _ } from "vue-underscore";
import jwtDecode from "jwt-decode";

function checkCorp(state) {
  var token = jwtDecode(state.token);
  var corp = localStorage.getItem("corp") || null;
  // Check that corp exists in corps list
  if (corp) {
    corp = JSON.parse(corp);
    //state.corp = state.corps.find((c) => c.id_corp == corp.id_corp);
  } else if (token.orgId && token.corp_info) {
    // Autoselect corp from list
    //state.corp = token.orgId;
    state.corp = token.corp_info;
    localStorage.setItem("corp", JSON.stringify(state.corp));
  } else {
    // Need to select manually
    state.corp = null;
    localStorage.removeItem("corp");
  }
  state._isSelectCorp = !!state.corp;
}

export function init(state) {
  state.status = "";
  state.corps = [];
  state.locale = localStorage.getItem("locale") || "ua";
  localStorage.setItem("locale", state.locale);
  this._vm.$i18n.set(state.locale);
  state.token = localStorage.getItem("token") || "";
  state._isLoggedIn = !!state.token;
  if (state.token && this.getters["main/isLoggedIn"]()) {
    state._isLoggedIn = true;
    axios.defaults.headers.common["token"] = state.token;
    checkCorp(state);
  } else {
    state.token = "";
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["token"];
  }
  state.axiosCancelTokens.forEach((request, i) => {
    if (request.cancel) {
      request.cancel();
    }
  });
  state.axiosCancelTokens = [];
}

export function update_token(state, token) {
  state.token = token;
  state._isLoggedIn = !!state.token;
  if (state.token && this.getters["main/isLoggedIn"]()) {
    state._isLoggedIn = true;
    axios.defaults.headers.common["token"] = state.token;
    checkCorp(state);
  } else {
    state.token = "";
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["token"];
  }
}

export function auth_request(state) {
  state.status = "loading";
}

export function auth_success(state, token) {
  state.status = "";
  state.token = token;
  localStorage.setItem("token", token);
  axios.defaults.headers.common["token"] = state.token;
  state._isLoggedIn = true;

  checkCorp(state);
}

export function auth_error(state) {
  state.status = "error";
  state._isLoggedIn = false;
  delete axios.defaults.headers.common["token"];
  localStorage.removeItem("token");
}

export function logout(state) {
  stop_timer(state, { name: "checkLogin" });
  state.status = "";
  state.token = "";
  state._isLoggedIn = false;
  state.userSettings = {};
  delete axios.defaults.headers.common["token"];
  localStorage.removeItem("token");
}

export function start_timer(state, { name, interval, callback }) {
  if (!state.timers[name]) state.timers[name] = setInterval(callback, interval);
}

export function stop_timer(state, { name }) {
  if (state.timers[name]) {
    clearTimeout(state.timers[name]);
    delete state.timers[name];
  }
}

function getLastDayOfQuarter(date) {
  var year = date.getFullYear();
  var quarterEndings = [
    [3, 31],
    [6, 30],
    [9, 30],
    [12, 31],
  ];

  var toDateObj = function(dates) {
    return new Date(year, dates[0] - 1, dates[1]);
  };

  var isBeforeEndDate = function(endDate) {
    return endDate >= date;
  };

  date.setHours(0, 0, 0, 0);

  return quarterEndings.map(toDateObj).filter(isBeforeEndDate)[0];
}

function getDateFilter(filterType) {
  var beginDay = new Date();
  var endDay = new Date();
  switch (filterType) {
    case "none":
      return { from: null, to: null };
    case "2week":
      beginDay.setDate(beginDay.getDate() - 7);
      beginDay.setHours(0);
      beginDay.setMinutes(0);
      beginDay.setSeconds(0);
      beginDay.setMilliseconds(0);
      endDay.setDate(endDay.getDate() + 7);
      endDay.setHours(23);
      endDay.setMinutes(59);
      endDay.setSeconds(59);
      endDay.setMilliseconds(0);
      return { from: beginDay, to: endDay };
    case "2month":
      beginDay.setDate(beginDay.getDate() - 30);
      beginDay.setHours(0);
      beginDay.setMinutes(0);
      beginDay.setSeconds(0);
      beginDay.setMilliseconds(0);
      endDay.setDate(endDay.getDate() + 30);
      endDay.setHours(23);
      endDay.setMinutes(59);
      endDay.setSeconds(59);
      endDay.setMilliseconds(0);
      return { from: beginDay, to: endDay };
    case "currentQuarter":
      var beginMonth = Math.floor(beginDay.getMonth() / 3) * 3;
      var endMonth = beginMonth + 2;
      beginDay.setMonth(beginMonth);
      beginDay.setDate(1);
      beginDay.setHours(0);
      beginDay.setMinutes(0);
      beginDay.setSeconds(0);
      beginDay.setMilliseconds(0);
      endDay = getLastDayOfQuarter(beginDay);
      endDay.setHours(23);
      endDay.setMinutes(59);
      endDay.setSeconds(59);
      endDay.setMilliseconds(0);
      return { from: beginDay, to: endDay };
    default:
      beginDay.setHours(0);
      beginDay.setMinutes(0);
      beginDay.setSeconds(0);
      beginDay.setMilliseconds(0);
      return { from: beginDay, to: null };
  }
}

export function update_user_settings(state, data) {
  var needUpdateFilter = false;
  if (state.userSettings && state.userSettings.userSettings && data && data.userSettings) {
    if (state.userSettings.userSettings.filterContractsByDate != data.userSettings.filterContractsByDate) {
      needUpdateFilter = true;
    }
  } else {
    if (data.userSettings && !state.filterByDateFrom && !state.filterByDateTo) needUpdateFilter = true;
  }
  if (needUpdateFilter) {
    var newDateFilter = getDateFilter(data.userSettings.filterContractsByDate);
    state.filterByDateFrom = newDateFilter.from;
    state.filterByDateTo = newDateFilter.to;
  }
  state.userSettings = _.extend(state.userSettings ? state.userSettings : {}, data ? data : {});
}

export function update_user_setting_and_reload(state, data) {
  state.userSettings = data ? data : {};
  this._vm.$loading({ target: "body", text: this._vm.$i18n.translate("Оновлення налаштувань...") });
  setTimeout(() => {
    window.location.reload();
  }, 200);
}

export function update_locale(state, locale) {
  var needReload = false;
  if (state.locale && locale) {
    if (state.locale != locale) {
      needReload = true;
    }
  }
  state.locale = locale;
  this._vm.$i18n.set(state.locale);
  localStorage.setItem("locale", locale);
  if (needReload) {
    this._vm.$loading({ target: "body", text: this._vm.$i18n.translate("Зміна локалі...") });
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }
}

export function add_cancel_token(state, token) {
  state.axiosCancelTokens.push(token);
}

export function clear_cancel_tokens(state, quiet) {
  state.axiosCancelTokens.forEach((request, i) => {
    if (request.cancel) {
      request.cancel(quiet ? "quiet" : "cancel");
    }
  });
  state.axiosCancelTokens = [];
}

export function set_global_loading(state, text) {
  if (text && text != "") {
    if (!state.globalLoading) {
      state.globalLoading = this._vm.$loading({ target: "body", text: text });
    } else {
      state.globalLoading.setText(text);
    }
  } else {
    if (state.globalLoading) {
      state.globalLoading.close();
    }
    state.globalLoading = null;
  }
}

export function update_global_loading(state, text) {
  if (state.globalLoading) {
    state.globalLoading.setText(this._vm.$i18n.translate(text));
  }
}

export function set_corp(state, { corp, disableReload }) {
  var needReload = false;
  if (state._isSelectCorp && corp) {
    if (state.corp.id_corp != corp.id_corp) {
      needReload = true;
    }
  } else {
    if (!state._isSelectCorp && corp) {
      needReload = true;
    } else if (state._isSelectCorp && !corp) {
      needReload = true;
    }
  }
  state.corp = corp;
  state._isSelectCorp = !!state.corp;
  if (corp) {
    localStorage.setItem("corp", JSON.stringify(corp));
  } else {
    localStorage.removeItem("corp");
  }
  if (needReload && !disableReload) {
    this._vm.$loading({ target: "body", text: this._vm.$i18n.translate("corps.select.wait") });
    setTimeout(() => {
      window.location = "/";
    }, 300);
  }
}

export function update_user_profile(state, data) {
  state.userProfile = data;
}

export function update_corps_list(state, data) {
  state.corps = data;
}

export function contracts_set_filter_by_date_from(state, filter) {
  state.filterByDateFrom = filter;
}

export function contracts_set_filter_by_date_to(state, filter) {
  state.filterByDateTo = filter;
}

export function update_server_info(state, info) {
  state.serverInfo = info;
}
