export function store(state) {
  if (state.sku.wait) return [];
  else return state.sku;
}

export function importStore(state) {
  return state.skuImport;
}

export function groups(state) {
  return state.groups;
}

export function wait(state) {
  return function() {
    return state.wait;
  };
}

export function waitInfo(state) {
  return function() {
    return state.waitInfo;
  };
}

export function waitStatus(state) {
  return function() {
    if (state.wait) {
      if (state.sku.length > 0) return vm.$i18n.translate("Рядків завантажено...") + state.sku.length;
      else return vm.$i18n.translate("Будь ласка, зачекайте...");
    } else return "";
  };
}

export function getInfo(state) {
  if (state.skuInfo && state.skuInfo.length) return state.skuInfo[0];
  else return null;
}
