export function init(state) {
  state.orgs = [];
  state.tree = [];
  state.wait = 0;
  state.treeWait = 0;
  state.childrens = [];
  state.parents = [];
  state.aliases = null;
  state.aliasesHistory = null;
  state.signatory = null;
}

export function orgs_update(state, orgs) {
  state.orgs = orgs;
}

export function orgs_update_append(state, orgs) {
  state.orgs = state.orgs.concat(orgs);
}

export function orgs_add(state, orgs) {
  var existIds = state.orgs.map((item) => item.axiomaId);
  var newIds = orgs.filter((item) => existIds.indexOf(item.axiomaId) == -1);
  state.orgs = state.orgs.concat(orgs);
}

export function orgs_del(state, orgs) {
  var delIds = orgs.map((item) => item.axiomaId);
  state.orgs = state.orgs.filter((item) => {
    return delIds.indexOf(item.axiomaId) == -1;
  });
}

export function orgs_set_wait(state, status) {
  state.wait = status;
}

export function tree_set_wait(state, status) {
  state.treeWait = status;
}

export function orgs_set_wait_inc(state) {
  if (!state.wait) state.wait = 1;
  else state.wait = state.wait + 1;
}

export function orgs_set_wait_dec(state) {
  if (state.wait && state.wait > 0) state.wait = state.wait - 1;
  else state.wait = 0;
}

export function tree_set_wait_inc(state) {
  if (!state.treeWait) state.treeWait = 1;
  else state.treeWait = state.treeWait + 1;
}

export function tree_set_wait_dec(state) {
  if (state.treeWait && state.treeWait > 0) state.treeWait = state.treeWait - 1;
  else state.treeWait = 0;
}

function getTreePath(currentItem, treeArr) {
  var result = [];
  if (currentItem) {
    result.push([currentItem.axiomaId]);
    var parentItem = treeArr.find((item) => {
      return item.axiomaId == currentItem.parent_id;
    });
    result = result.concat(getTreePath(parentItem, treeArr));
  }
  return result;
}

function updatePaths(tree) {
  return tree.map((item) => {
    item.path = getTreePath(item, tree).reverse();
    return item;
  });
}

export function tree_update(state, tree) {
  state.tree = updatePaths(tree);
}

export function tree_update_append(state, tree) {
  state.tree = updatePaths(state.tree.concat(tree));
}

export function tree_add(state, tree) {
  var existIds = state.tree.map((item) => item.axiomaId);
  var newIds = tree.filter((item) => existIds.indexOf(item.axiomaId) == -1);
  state.tree = updatePaths(state.tree.concat(newIds));
}

export function tree_del(state, tree) {
  var delIds = tree.map((item) => item.axiomaId);
  state.tree = state.tree.filter((item) => {
    return delIds.indexOf(item.axiomaId) == -1;
  });
}

export function add_childrens(state, { data, parent }) {
  var parentNode = state.orgs.find((item) => item.axiomaId == parent.id);
  if (parentNode) parentNode.loadedChidrens = (parentNode.loadedChidrens ? parentNode.loadedChidrens : 0) + data.length;
  var existIds = state.childrens.map((item) => item.axiomaId);
  var newIds = data.filter((item) => existIds.indexOf(item.axiomaId) == -1);
  state.childrens = state.childrens.concat(newIds);
}

export function clear_childrens(state, tree) {
  state.childrens = [];
}

export function add_parents(state, { data }) {
  var existIds = state.parents.map((item) => item.axiomaId);
  var newIds = data.filter((item) => existIds.indexOf(item.axiomaId) == -1);
  state.parents = updatePaths(state.parents.concat(newIds));
}

export function clear_parents(state, tree) {
  state.parents = [];
}

export function orgs_import_update(state, orgs) {
  state.orgsImport = orgs;
}

export function orgs_set_turnover(state, turnover) {
  state.turnover = turnover;
}

function getPathByOrg(orgs, axiomaId) {
  var org = orgs.find((org) => org.axiomaId == axiomaId);
  if (org) return org.path;
  else return null;
}

// For Aliases
function getTreePathForOrg(currentItem, treeArr) {
  var result = [];
  if (currentItem) {
    result.push([currentItem.axiomaId]);
    var parentItem = _.find(treeArr, (item) => {
      return item.axiomaId == currentItem.parentId;
    });
    result = result.concat(getTreePathForOrg(parentItem, treeArr));
  }
  return result;
}

// Apply Aliases
function applyAliases(orgs, aliases) {
  return orgs.map((org) => {
    var alias = aliases.find((a) => a.axiomaId == org.axiomaId);
    if (alias) org.parentId = alias.parentAxiomaId;
    return org;
  });
}

// Restore Pathes
function restorePathes(orgs) {
  return orgs.map((org) => {
    org.path = getTreePathForOrg(org, orgs).reverse();
    return org;
  });
}

function applyOrgsTreeByAliasesList(orgs, aliases) {
  var orgsWithAliases = applyAliases(orgs, aliases);
  var orgsWithAliasesAndPathes = restorePathes(orgsWithAliases);
  return orgsWithAliasesAndPathes;
}

export function orgs_set_aliases(state, data) {
  state.aliases = data;
}

// export function orgs_add_new_alias(state, newAlias) {
//   //1 chec for exists alias for this axiomaId
//   var oldAliases = state.aliases.aliases.filter((alias) => alias.axiomaId !== newAlias.axiomaId);
//   var newOrgs = applyOrgsTreeByAliasesList(state.aliases.orgs, [newAlias]);
//   var newAliases = state.aliases.aliases.push(newAlias);
//   state.aliases = { orgs: newOrgs, aliases: newAliases };
// }

export function orgs_set_aliases_history(state, aliasesHistory) {
  state.aliasesHistory = aliasesHistory;
}

export function orgs_set_signatory(state, data) {
  state.signatory = data;
}

export function orgs_add_signatory(state, signatory) {
  var existSignatoryIdx = state.signatory.findIndex((item) => item._id == signatory._id);
  if (existSignatoryIdx != -1) state.signatory[existSignatoryIdx] = signatory;
  else state.signatory.push(signatory);
}

export function orgs_update_signatory(state, signatory) {
  var existSignatoryIdx = state.signatory.findIndex((item) => item._id == signatory._id);
  if (existSignatoryIdx != -1) state.signatory[existSignatoryIdx] = signatory;
}

export function orgs_delete_signatory(state, signatory) {
  var existSignatoryIdx = state.signatory.findIndex((item) => item._id == signatory._id);
  if (existSignatoryIdx != -1) state.signatory.splice(existSignatoryIdx, 1);
}

export function orgs_assign_signatory(state, { axiomaId, signatory }) {
  var orgIdx = state.aliases.orgs.findIndex((item) => item.axiomaId == axiomaId);
  if (orgIdx != -1) state.aliases.orgs[orgIdx].signatory = signatory;
}

export function orgs_clear_signatory(state, org) {
  var orgIdx = state.aliases.orgs.findIndex((item) => item.axiomaId == org.axiomaId);
  if (orgIdx != -1) state.aliases.orgs[orgIdx].signatory = null;
}
