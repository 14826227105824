import Vue from "vue";
import "./plugins/axios";
import "./plugins/element";
import "./plugins/underscore";
import "./plugins/moment";
import "./plugins/i18n";
import "./plugins/global";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import { sync } from "vuex-router-sync";

store.dispatch("reset");

sync(store, router);

Vue.config.productionTip = false;
Vue.config.warnHandler = false;

// Bus
export const bus = new Vue();

// AG-GRID
import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(
  "CompanyName=LLC DIGITAL SOFTDRIVE_on_behalf_of_Softinform LLC,LicensedGroup=SOFTINFORM,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,ExpiryDate=4_February_2021_[v2]_MTYxMjM5NjgwMDAwMA==cccd130e27aca148f2d12051d0014da8"
);

// InputMask
// import Inputmask from "inputmask";

// Vue.directive("mask", {
//   bind: function(el, binding) {
//     if (!binding.value) {
//       return;
//     }

//     Inputmask(binding.value).mask(el.getElementsByTagName("INPUT")[0]);
//   },
// });

// V-Mask
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

// Loadash
import VueLodash from "vue-lodash";
import lodash from "lodash";
Vue.use(VueLodash, { name: "custom", lodash: lodash });

window.vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

window.$tt = window.vm.$i18n.translate;

// set the start locale to use
//window.vm.$i18n.set(store.getters["main/locale"]);
