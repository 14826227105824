import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Error404 from "@/views/404.vue";
import Error500 from "@/views/500.vue";

Vue.use(VueRouter);

function corpNameEllipsis(store) {
  var corp = store.getters["main/corp"]();
  return corp.corp_name.slice(0, 30) + (corp.corp_name.length > 30 ? "..." : "");
}

const routes = [
  { name: "Login", path: "/login", component: () => import("@/views/Login") },
  {
    name: "Contracts",
    path: "/",
    component: () => import("@/views/Contracts"), //Contracts,
    meta: {
      protected: true,
      preloadData: [{ load: "contracts/load" }],
      breadCrumbs: [
        () => {
          return { name: corpNameEllipsis(store), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return { name: $tt("Маркетингові договори") };
        },
      ],
      title: () => $tt("Маркетингові договори"),
    },
  },
  {
    name: "EditRestricts",
    path: "/contract/:id",
    component: () => import("@/views/Restricts"), //Restricts,
    meta: {
      protected: true,
      preloadData: [{ load: "contracts/loadOne" }, { load: "restricts/load" }],
      breadCrumbs: [
        () => {
          return { name: corpNameEllipsis(store), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return { name: $tt("Маркетингові договори"), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return {
            name:
              $tt("Договір") +
              ": " +
              store.getters["contracts/contract"]().name.slice(0, 30) +
              (store.getters["contracts/contract"]().name.length > 30 ? "..." : ""),
            title: $tt("Договір") + ": " + store.getters["contracts/contract"]().name,
          };
        },
        () => {
          return { name: $tt("Умови"), title: $tt("Умови") };
        },
      ],
      title: () => $tt("Умови"),
    },
  },
  {
    name: "EditRestrictDetails",
    path: "/contract/:id/restrict/:id2",
    component: () => import("@/views/RestrictDetails.vue"),
    meta: {
      protected: true,
      preloadData: [{ load: "contracts/loadOne" }, { load: "restricts/loadOne" }, { load: "orgs/load_turnover" }],
      breadCrumbs: [
        () => {
          return { name: corpNameEllipsis(store), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return { name: $tt("Маркетингові договори"), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return {
            name:
              $tt("Договір") +
              ": " +
              store.getters["contracts/contract"]().name.slice(0, 30) +
              (store.getters["contracts/contract"]().name.length > 30 ? "..." : ""),
            title: $tt("Перейти до ") + $tt("Договір") + ": " + store.getters["contracts/contract"]().name,
            to: () => {
              return "/contract/" + store.getters["contracts/contract"]()._id;
            },
          };
        },
        () => {
          return {
            name: $tt("Умови"),
            title: $tt("Перейти до ") + $tt("Договір") + ": " + store.getters["contracts/contract"]().name,
            to: () => {
              return "/contract/" + store.getters["contracts/contract"]()._id;
            },
          };
        },
        () => {
          return { name: $tt("Умова") + ": " + store.getters["restricts/restrict"]().name };
        },
      ],
      title: () => $tt("Умови"),
    },
  },
  {
    name: "EditRestrictDetailsSet",
    path: "/contract/:id/restrictset/:id2",
    component: () => import("@/views/RestrictDetailsSet.vue"),
    meta: {
      protected: true,
      preloadData: [{ load: "contracts/loadOne" }, { load: "restricts/loadOne" }, { load: "templates/load" }],
      breadCrumbs: [
        () => {
          return { name: corpNameEllipsis(store), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return { name: $tt("Маркетингові договори"), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return {
            name:
              $tt("Договір") +
              ": " +
              store.getters["contracts/contract"]().name.slice(0, 30) +
              (store.getters["contracts/contract"]().name.length > 30 ? "..." : ""),
            title: $tt("Перейти до ") + $tt("Договір") + ": " + store.getters["contracts/contract"]().name,
            to: () => {
              return "/contract/" + store.getters["contracts/contract"]()._id;
            },
          };
        },
        () => {
          return {
            name: $tt("Умови"),
            title: $tt("Перейти до ") + $tt("Договір") + ": " + store.getters["contracts/contract"]().name,
            to: () => {
              return "/contract/" + store.getters["contracts/contract"]()._id;
            },
          };
        },
        () => {
          return { name: $tt("Викладка") + ": " + store.getters["restricts/restrict"]().name };
        },
      ],
      title: () => $tt("Умови"),
    },
  },
  {
    name: "EditRestrictsEmpty",
    path: "/contract",
    redirect: "/",
  },
  {
    name: "SelectCorp",
    path: "/corp",
    component: () => import("@/views/Corps"),
    meta: {
      protected: true,
    },
  },
  {
    name: "404redir",
    path: "/404",
    component: Error404,
  },
  {
    name: "500redir",
    path: "/500",
    component: Error500,
  },
  {
    name: "404",
    path: "*",
    component: Error404,
  },
  {
    name: "BonusMatrix",
    path: "/bonusMatrix",
    component: () => import("@/views/BonusMatrix"), //BonusMatrix,
    meta: {
      protected: true,
      preloadData: [{ load: "analytics/getBonusMatrix" }],
      breadCrumbs: [
        () => {
          return { name: corpNameEllipsis(store), to: "/", title: $tt("Перейти до ") + $tt("Маркетингові договори") };
        },
        () => {
          return { name: $tt("Матриція бонусації"), to: "/bonusMatrix", title: $tt("Перейти до ") + $tt("Матриця бонусації") };
        },
      ],
      title: () => $tt("Матриця бонусації"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function defaultRouteAction(to, from, next, store) {
  var lastRoute = localStorage.getItem("LAST_ROUTE_KEY");
  localStorage.removeItem("LAST_ROUTE_KEY");
  if (lastRoute && lastRoute != "/") {
    next(lastRoute);
  } else {
    if (to.meta.preloadData) {
      store.dispatch("main/setGlobalLoading", " ");
      setTimeout(() => {
        Promise.all(
          to.meta.preloadData.map((data) => {
            return store.dispatch(data.load, to.params);
          })
        )
          .then((results) => {
            next();
            store.dispatch("main/setGlobalLoading");
          })
          .catch((err) => {
            store.dispatch("main/setGlobalLoading");
            store._vm.$alert(parseErrorExt(err), "Server error!", { confirmButtonText: "OK" }).then(() => {
              router.push("/login");
            });
          });
      }, 10);
    } else next();
  }
}

function __defaultRouteAction(to, from, next, store) {
  if (store.getters["main/isNeedToSelectCorp"]) {
    if (store.getters["main/corp"]()) {
      store
        .dispatch("main/setCurrentCorp", { corp: store.getters["main/corp"](), router: router })
        .then(() => {
          defaultRouteAction(to, from, next, store);
        })
        .catch((err) => {
          next("/corp");
        });
    } else {
      if (to.path == "/corp") {
        defaultRouteAction(to, from, next, store);
      } else next("/corp");
    }
  } else {
    defaultRouteAction(to, from, next, store);
  }
}

router.beforeEach((to, from, next) => {
  store.dispatch("main/cancelAllRequests");
  if (to.matched.some((record) => record.meta.protected)) {
    store.dispatch("main/checkLogin", { vm: store._vm, router, next });
    if (store.getters["main/isLoggedIn"]()) {
      if (store.getters["main/isNeedToLoadUserProfile"]) {
        store
          .dispatch("main/loadUserProfile", { vm: store._vm, router: router })
          .then(() => {
            __defaultRouteAction(to, from, next, store);
          })
          .catch((err) => {
            store._vm.$alert(parseErrorExt(err), "Server error!", { confirmButtonText: "OK" }).then(() => {
              router.push("/login");
            });
          });
      } else {
        __defaultRouteAction(to, from, next, store);
      }
    } else {
      if (to.fullPath != "/") localStorage.setItem("LAST_ROUTE_KEY", to.fullPath);
      Vue.nextTick(() => {
        router.push("/login");
      });
    }
  } else {
    if (to.path == "/login") {
      store.dispatch("logout");
    }
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to && to.meta && to.meta.title) {
      if (typeof to.meta.title == "function") {
        document.title = $tt("app.title") + " :: " + to.meta.title();
      } else document.title = $tt("app.title") + " :: " + to.meta.title || $tt("app.title");
    }
  });
});

export default router;
