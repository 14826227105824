<template>
  <div id="app" v-cloak>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  created() {
    document.title = this.$i18n.translate("app.title");
  },
};
</script>

<style lang="scss">
$apply: #26ae4d;
$borederColor: #535950;
@font-face {
  font-family: "Rubik", sans-serif;
  //src: url("assets/fonts/Rubik/Rubik.ttf") format("truetype");
  src: url("https://fonts.googleapis.com/css?family=Rubik");
}
#app {
  /*font-family: Rubik, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  background: #3dba61;
  background-size: contain;
  height: 100%;
}
html {
  height: 100%;
  font-family: Rubik, sans-serif;
}

.header1 {
  color: #fff;
  background: #26ae4d;
  /*line-height: 32px;*/
  padding: 0 !important;
}
.header2 {
  background: #fff;

  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
}
.header3 {
  background: #fff;
  line-height: 48px;
  height: 48px;
  padding: 0 !important;
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-input__icon {
    height: 32px;
    line-height: 32px;
  }
}

.push {
  margin-left: auto;
  padding-left: 19px;
}
.is-align-center {
  align-items: center;
}
.back {
  width: 48px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: linear-gradient(270deg, #109c38 0%, #26ae4d 100%);
  height: 32px;
}
.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /*width: 155px;*/
  background: linear-gradient(90deg, #109c38 0.39%, #26ae4d 100.39%);
  height: 32px;
}

.profile > * {
  margin-left: 8px;
  line-height: 13px;
  font-size: 12px;
  color: #fff;
}

.el-message-box,
.el-select-dropdown__item,
.el-select-dropdown__item.selected,
.el-dropdown-menu__item,
.dropdown-menu__item.selected,
.el-date-picker {
  font-family: Rubik, sans-serif;
}

div.el-message-box > div.el-message-box__header {
  background: #eff2e9 !important;
}

.el-message-box__btns {
  text-align: center;
  button.el-button--primary {
    border-color: $apply;
    background: $apply;
    width: 150px;
  }

  button.el-button--default {
    width: 150px;
  }
}

.my-dialog {
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    padding: 10px;
  }
  .el-input__inner:hover {
    border-color: $borederColor;
  }
  .el-input__prefix {
    height: 32px;
    border-radius: 3px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 32px;
  }
  .el-form-item__label {
    line-height: 16px;
    padding: 0;
  }
  .el-dialog--center .el-dialog__body {
    padding: 16px 16px 0 16px !important;
  }
  .el-dialog--center .el-dialog__header {
    background: #eff2e9;
    padding: 0px !important;
  }
  .el-dialog--center .el-dialog__title {
    line-height: 48px;
    /*font-family: Rubik, sans-serif;*/
    font-size: 20px;
    font-weight: 500;
  }
  .cancel-button {
    width: 128px;
    text-decoration: underline;
  }
  .apply-button {
    width: 176px;
  }
  .el-form-item__error {
    padding-top: 0px !important;
  }
  .el-form-item {
    margin-bottom: 16px;
  }
  .el-dialog--center .el-dialog__headerbtn {
    top: 16px;
    right: 16px;
  }
}

.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  .el-dialog {
    margin: 0 auto !important;
    height: 90%;
    max-height: 850px;
    min-height: 200px;
    overflow: hidden;
    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 48px;
      bottom: 72px;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
    .el-dialog__body::-webkit-scrollbar {
      width: 10px;
    }
    .el-dialog__body::-webkit-scrollbar-track {
      background-color: #fff;
    }
    .el-dialog__body::-webkit-scrollbar-thumb {
      background-color: rgba(200, 200, 200, 0.5);
      border-radius: 5px;
    }
    .el-dialog__body::-webkit-scrollbar-thumb:hover {
      background-color: rgba(200, 200, 200, 0.8);
      transition: background-color, 0.3s;
    }
    .el-dialog__footer {
      bottom: 0px;
      position: absolute;
      z-index: 2;
      background: white;
      width: 100%;
    }
  }
}

.middle {
  .el-dialog {
    height: 60% !important;
    max-height: 600px !important;
  }
}

.small {
  .el-dialog {
    height: 50% !important;
    max-height: 425px !important;
  }
}

@import "../node_modules/ag-grid-enterprise/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-enterprise/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      alpine-active-color: #036d0c,
      odd-row-background-color: #f2fbee,
    )
  );
  .ag-cell {
    line-height: 32px;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .ag-header-cell-label {
    justify-content: center;
  }
  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ag-cell-inline-editing {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .ag-body-viewport {
    text-align: left !important;
  }
  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: 32px;
  }

  .ag-status-name-value {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ag-cell-inline-editing {
    height: 100% !important;
  }
  .ag-status-bar {
    padding-right: 24px;
    padding-left: 24px;
    line-height: 1pt !important;
  }
  .ag-menu-option-text,
  .ag-compact-menu-option-text {
    text-align: left !important;
  }
  .ag-cell-value,
  .ag-group-value {
    text-overflow: clip !important;
  }
  .ag-details-row {
    padding: 0px !important;
  }
  .ag-column-hover {
    background-color: var(--ag-column-hover-color, rgba(33, 150, 243, 0.04)) !important;
    transition-duration: 0.3s;
  }
}

.header-input {
  height: 32px;
  padding: 0 12px;
}

i.icon-action,
img.icon-action {
  margin-right: 5px;
  opacity: 0.4;
}

i.icon-action:hover,
img.icon-action:hover {
  opacity: 0.9;
  transition: opacity, 0.4s;
}

// .action-cell {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;
// }

// Import Font Awesome 5 Free
$fa-css-prefix: "el-icon-fa";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// Override Element UI's icon font
.fas {
  font-family: "Font Awesome 5 Free" !important;
}

.fab {
  font-family: "Font Awesome 5 Brands" !important;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:after,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 4px;
  margin-right: 0px;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: none !important;
}

.el-dropdown-menu__item > img.svg {
  padding-right: 8px;
  vertical-align: middle;
  // height: 16px;
}

.el-dropdown-menu__item > img.disabled {
  opacity: 0.5 !important;
}

.el-input__prefix {
  color: #036d0c !important;
}
i.el-icon-circle-close {
  color: red !important;
}

.grid-cell-red-text {
  color: red !important;
}
.grid-cell-yellow-text {
  color: rgba(255, 255, 0, 0.5) !important;
}
.grid-cell-navy-text {
  color: rgba(50, 90, 175, 0.815) !important;
}
.grid-cell-green-text {
  color: green !important;
}

.bigAlert {
  width: 50%;
}

[v-cloak] {
  display: block;
  padding: 50px 0;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner 0.6s linear infinite;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  & > div {
    display: none;
  }
}
</style>
