import Vue from "vue";
import Vuex from "vuex";
import main from "./main";
import contracts from "./contracts";
import orgs from "./orgs";
import sku from "./sku";
import restricts from "./restricts";
import analytics from "./analytics";
import templates from "./templates";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {
    reset(state) {
      Object.keys(state).forEach((key) => {});
    },
  },
  actions: {
    reset(context) {
      Object.keys(context.state).forEach((key) => {
        if (key != "route") context.dispatch(key + "/init");
      });
      context.commit("reset");
    },
    logout({ commit }) {
      this.dispatch("main/logout").then(() => {
        this.dispatch("reset");
      });
    },
  },

  modules: { main, contracts, orgs, sku, restricts, analytics, templates },
});
