import { _ } from "vue-underscore";

export function init(context) {
  context.commit("init");
}

export function get_list(context, filter) {
  return new Promise((resolve, reject) => {
    axios(
      {
        url: "/api/axioma/get/orgs",
        data: { data: filter },
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {
        context.commit("orgs_set_wait_dec");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          if (resp.data.more) {
            if (resp.data.more.skip > 0) context.commit("orgs_add", resp.data.data);
            else context.commit("orgs_update", resp.data.data);
            get_list(context, _.extend(filter, { skip: resp.data.more.fetch + resp.data.more.skip }))
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            context.commit("orgs_update", resp.data.data);
            resolve();
          }
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
    context.commit("orgs_set_wait_inc");
  });
}

export function del({ commit }, orgs) {
  commit("orgs_del", orgs);
}

export function add({ commit }, orgs) {
  commit("orgs_add", orgs);
}

export function get_tree(context, filter) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/axioma/get/tree",
      data: { data: filter },
      method: "POST",
    })
      .finally(() => {
        context.commit("tree_set_wait_dec");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          if (resp.data.more) {
            if (resp.data.more.skip > 0) context.commit("tree_add", resp.data.data);
            else context.commit("tree_update", resp.data.data);
            return get_tree(context, _.extend(filter, { skip: resp.data.more.fetch + resp.data.more.skip }));
          } else {
            context.commit("tree_update", resp.data.data);
            resolve();
          }
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
    context.commit("tree_set_wait_inc");
  });
}

export function add_tree(context, filter) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/axioma/get/tree",
      data: { data: filter },
      method: "POST",
    })
      .finally(() => {
        context.commit("tree_set_wait_dec");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          if (resp.data.more) {
            context.commit("tree_add", resp.data.data);
            add_tree(context, _.extend(filter, { skip: resp.data.more.fetch + resp.data.more.skip }))
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            context.commit("tree_add", resp.data.data);
            resolve();
          }
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
    context.commit("tree_set_wait_inc");
  });
}

export function getChildrens(context, filter) {
  if (!filter.count) {
    filter.count = 1;
    context.commit("clear_childrens");
  } else {
    filter.count++;
  }
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/axioma/get/childrens",
      data: { data: filter },
      method: "POST",
    })
      .finally(() => {
        context.commit("tree_set_wait_dec");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          if (resp.data.more) {
            context.commit("add_childrens", { data: resp.data.data, parent: filter });
            getChildrens(context, _.extend(filter, { skip: resp.data.more.fetch + resp.data.more.skip }))
              .then(() => {
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            context.commit("add_childrens", { data: resp.data.data, parent: filter });
            resolve();
          }
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
    context.commit("tree_set_wait_inc");
  });
}

export function getParents(context, filter) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/axioma/get/parents",
      data: { data: filter },
      method: "POST",
    })
      .finally(() => {
        context.commit("tree_set_wait_dec");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("add_parents", { data: resp.data.data });
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
    context.commit("tree_set_wait_inc");
  });
}

export function addCustomOrgs({ commit }, data) {
  commit("orgs_add", data);
}

export function treeDel({ commit }, tree) {
  commit("tree_del", tree);
}

export function clearChildrens({ commit }) {
  commit("clear_childrens");
}

export function treeAdd({ commit }, tree) {
  commit("tree_add", tree);
}

export function treeClear({ commit }) {
  commit("tree_update", []);
}

export function parentsClear({ commit }) {
  commit("clear_parents");
}

export function importFromUploadedFileXLS({ commit }, { fileName, params }) {
  return new Promise((resolve, reject) => {
    commit("orgs_set_wait", true);
    commit("orgs_import_update", []);
    axios(
      {
        url: "/api/axioma/importFromXLS",
        data: { fileName, ...params },
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 5, //!!!
      }
    )
      .finally(() => {
        commit("orgs_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("orgs_import_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("orgs_import_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("orgs_import_update", []);
        reject(err);
      });
  });
}

export function importWithPlansFromUploadedFileXLS({ commit }, { fileName, params }) {
  return new Promise((resolve, reject) => {
    commit("orgs_set_wait", true);
    commit("orgs_import_update", []);
    axios(
      {
        url: "/api/axioma/importWithPlansFromXLS",
        data: { fileName, ...params },
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 5, //!!!
      }
    )
      .finally(() => {
        commit("orgs_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("orgs_import_update", resp.data.data);
          resolve(resp.data);
        } else {
          commit("orgs_import_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("orgs_import_update", []);
        reject(err);
      });
  });
}

export function load_turnover(context, filter) {
  return new Promise((resolve, reject) => {
    if (context.getters.turnover) {
      resolve();
    } else {
      context.commit("main/update_global_loading", "Завантаження товарообігу...", { root: true });
      axios(
        {
          url: "/api/axioma/get/turnover",
          data: {},
          method: "POST",
        },
        {
          timeout: 1000 * 60 * 2, //!!!
        }
      )
        .finally(() => {
          context.commit("orgs_set_wait_dec");
          //          context.commit("main/set_global_loading", "", { root: true });
        })
        .then((resp) => {
          if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
            context.commit("orgs_set_turnover", resp.data.data);
            resolve();
          } else {
            reject(resp.data.msg);
          }
        })
        .catch((err) => {
          reject(err);
        });
      context.commit("orgs_set_wait_inc");
    }
  });
}

export function getAliases(context, filter) {
  return new Promise((resolve, reject) => {
    context.commit("main/update_global_loading", "Завантаження організацій...", { root: true });
    axios(
      {
        url: "/api/axioma/get/orgsAliases",
        data: {},
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {
        context.commit("orgs_set_wait_dec");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("orgs_set_aliases", resp.data.data);
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
    context.commit("orgs_set_wait_inc");
  });
}

export function addAlias(context, newAlias) {
  return new Promise((resolve, reject) => {
    axios(
      {
        url: "/api/axioma/add/alias",
        data: newAlias,
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function clearAlias(context, alias) {
  return new Promise((resolve, reject) => {
    axios(
      {
        url: "/api/axioma/add/alias",
        data: { axiomaId: alias.axiomaId, parentAxiomaId: -1 },
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getOrgAliasesHistory(context, axiomaId) {
  return new Promise((resolve, reject) => {
    context.commit("orgs_set_aliases_history", null);
    axios(
      {
        url: "/api/axioma/get/aliasesHistory",
        data: { axiomaId: axiomaId },
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("orgs_set_aliases_history", resp.data.data);
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function clearOrgAliasesHistory(context) {
  context.commit("orgs_set_aliases_history", null);
}

export function getSignatory(context, filter) {
  return new Promise((resolve, reject) => {
    context.commit("main/update_global_loading", "Завантаження організацій...", { root: true });
    axios(
      {
        url: "/api/axioma/get/signatoryList",
        data: {},
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {
        context.commit("orgs_set_wait_dec");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("orgs_set_signatory", resp.data.data);
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
    context.commit("orgs_set_wait_inc");
  });
}

export function createSignatory(context, signatory) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/axioma/add/signatory",
      data: { data: signatory },
      method: "POST",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("orgs_add_signatory", resp.data.data);
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateSignatory({ commit }, updatedSignatory) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/axioma/update/signatory",
      data: { signatoryId: updatedSignatory._id, data: updatedSignatory },
      method: "POST",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("orgs_update_signatory", updatedSignatory);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteSignatory({ commit }, deleteSignatory) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/axioma/delete/signatory",
      data: { signatoryId: deleteSignatory._id },
      method: "POST",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("orgs_delete_signatory", deleteSignatory);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function assignSignatory(context, data) {
  return new Promise((resolve, reject) => {
    axios(
      {
        url: "/api/axioma/assign/signatoryAndOrg",
        data: { data },
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("orgs_assign_signatory", { axiomaId: data.axiomaId, signatory: data.signatory });
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function clearSignatory(context, axiomaId) {
  return new Promise((resolve, reject) => {
    axios(
      {
        url: "/api/axioma/clear/signatoryForOrg",
        data: axiomaId,
        method: "POST",
      },
      {
        timeout: 1000 * 60 * 2, //!!!
      }
    )
      .finally(() => {})
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("orgs_clear_signatory", axiomaId);
          resolve();
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
