export function init(context) {
  context.commit("init");
}

export function getBonusMatrix({ commit }) {
  return new Promise((resolve, reject) => {
    commit("analytics_set_wait", true);
    commit("main/update_global_loading", this._vm.$i18n.translate("Завантаження матриці..."), { root: true });
    axios({
      url: "/api/analytics/get/bonusMatrix",
      method: "POST",
    })
      .finally(() => {
        commit("analytics_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("analytics_bonus_matrix_update", resp.data.data || {});
          resolve(resp);
        } else {
          commit("analytics_bonus_matrix_update", {});
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("analytics_bonus_matrix_update", {});
        reject(err);
      });
  });
}

export function clearBonusMatrixHistory({ commit }) {
  commit("analytics_bonus_matrix_history_update", []);
}

export function getBonusMatrixHistory({ commit }, { contractId, axiomaId }) {
  return new Promise((resolve, reject) => {
    commit("analytics_set_wait", true);
    axios({
      url: "/api/analytics/get/bonusMatrixHistory",
      method: "POST",
      data: { contractId, axiomaId },
    })
      .finally(() => {
        commit("analytics_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("analytics_bonus_matrix_history_update", resp.data.data || []);
          resolve(resp);
        } else {
          commit("analytics_bonus_matrix_history_update", []);
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        commit("analytics_bonus_matrix_history_update", []);
        reject(err);
      });
  });
}

export function saveBonusMatrixCoeff({ commit }, { contractId, axiomaId, coeff }) {
  return new Promise((resolve, reject) => {
    commit("analytics_set_wait", true);
    axios({
      url: "/api/analytics/set/bonusMatrixCoeff",
      method: "POST",
      data: { contractId, axiomaId, coeff },
    })
      .finally(() => {
        commit("analytics_set_wait", false);
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          commit("analytics_bonus_matrix_update_coeff", resp.data.data);
          resolve(resp.data.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
