export default function() {
  return {
    restricts: [],
    wait: false,
    waitStatus: null,
    restrict: null,
    orgs: [],
    restrictsAndRules: [],
    restrictImport: [],
    tempSKU: [],
    skuWait: false,
  };
}
