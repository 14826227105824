import { _ } from "vue-underscore";

export function bonusMatrixColumns(state) {
  return function(filter) {
    var filterByName = filter && filter.byName && filter.byName != "" ? filter.byName : null;
    var columns = [];
    //columns.push({ headerName: $tt("Назва"), field: "orgName", width: 350, headerTooltip: $tt("Назва"), pinned: "left" });
    columns.push({ headerName: $tt("ЄДРПОУ"), field: "okpo", width: 150, headerTooltip: $tt("ЄДРПОУ"), pinned: "left", type: "rightAligned" });
    state.bonusMatrix.contracts
      .filter((item) => {
        if (filterByName && item.name.toLowerCase().search(filterByName.toLowerCase()) == -1) {
          return false;
        }
        if (filter && filter.byDateFrom && !filter.byDateTo && Date.parse(item.endDate) < Date.parse(filter.byDateFrom)) {
          return false;
        } else if (filter && filter.byDateTo && !filter.byDateFrom && Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) {
          return false;
        } else if (filter && filter.byDateFrom && filter.byDateTo) {
          if (Date.parse(item.endDate) < Date.parse(filter.byDateFrom) || Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) return false;
        }
        return true;
      })
      .forEach((contract) => {
        if (filter.onlyWithCustomCoeff) {
          var customCoeffExists = state.bonusMatrix.orgs.find((org) => {
            //return state.bonusMatrix.coeff.find((c) => c._id.axiomaId == org.axiomaId && c._id.contractId == contract._id);
            return state.bonusMatrix._coeff[contract._id + "_" + org.axiomaId];
          });
          if (!customCoeffExists) return;
        }
        columns.push({ headerName: contract.name, field: contract._id, width: 130, headerTooltip: contract.name, type: "rightAligned" });
      });
    return columns;
  };
}

export function bonusMatrixRows(state) {
  return function(filter) {
    var rows = [];
    var filterByName = filter && filter.byName && filter.byName != "" ? filter.byName : null;
    state.bonusMatrix.orgs.forEach((org) => {
      var row = { axiomaId: org.axiomaId, orgName: org.name, rowWithCustomCoeff: false, okpo: org.okpo, path: org.path /*, childCount: 1*/ };
      state.bonusMatrix.contracts
        .filter((item) => {
          if (filterByName && item.name.toLowerCase().search(filterByName.toLowerCase()) == -1) {
            return false;
          }
          if (filter && filter.byDateFrom && !filter.byDateTo && Date.parse(item.endDate) < Date.parse(filter.byDateFrom)) {
            return false;
          } else if (filter && filter.byDateTo && !filter.byDateFrom && Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) {
            return false;
          } else if (filter && filter.byDateFrom && filter.byDateTo) {
            if (Date.parse(item.endDate) < Date.parse(filter.byDateFrom) || Date.parse(item.beginDate) > Date.parse(filter.byDateTo)) return false;
          }
          return true;
        })
        .forEach((contract) => {
          //var count = state.bonusMatrix.coeff.length;
          var coeff = state.bonusMatrix._coeff[contract._id + "_" + org.axiomaId];
          //var coeff = state.bonusMatrix.coeff.find((i) => i._id.axiomaId == org.axiomaId && i._id.contractId == contract._id);
          if (coeff) {
            row[contract._id] = { coeff: coeff.coeff, historyCount: coeff.historyCount };
            row.rowWithCustomCoeff = true;
          }
        });
      if (filter.onlyWithCustomCoeff) {
        if (row.rowWithCustomCoeff) rows.push(row);
      } else rows.push(row);
    });
    return rows;
  };
}

export function dataExists(state) {
  return function() {
    return state.bonusMatrix && state.bonusMatrix.coeff && state.bonusMatrix.coeff.length;
  };
}

export function bonusMatrixHistory(state) {
  return function() {
    return state.history;
  };
}

export function bonusMatrixCoeff(state) {
  return function() {
    return state.bonusMatrix.coeff;
  };
}

export function bonusMatrixRow(state) {
  return function(axiomaId) {
    var row = null;
    var org = state.bonusMatrix.orgs.find((org) => org.axiomaId == axiomaId);
    if (org) {
      row = { axiomaId: org.axiomaId, orgName: org.name, rowWithCustomCoeff: false, okpo: org.okpo, path: org.path };
      state.bonusMatrix.contracts.forEach((contract) => {
        var coeff = state.bonusMatrix._coeff[contract._id + "_" + org.axiomaId];
        if (coeff) {
          row[contract._id] = { coeff: coeff.coeff, historyCount: coeff.historyCount };
          row.rowWithCustomCoeff = true;
        }
      });
    }
    return row;
  };
}
