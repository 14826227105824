import axios from "axios";
import { _ } from "vue-underscore";

export function init(state) {
  state.contracts = [];
  state.wait = false;
  state.contract = null;
  state.orgs = [];
}

// Contracts mutations
export function contracts_load(state, contracts) {
  state.contracts = contracts;
}

export function contracts_loadOne(state, contract) {
  state.contract = contract;
  state.contract.fullLoaded = true;
  contracts_update(state, contract);
}

export function contracts_create(state, newContract) {
  state.contracts.push(newContract);
}

export function contracts_update(state, updatedContract) {
  var contractIdx = _.findIndex(state.contracts, function(contract) {
    return contract._id == updatedContract._id;
  });
  if (contractIdx >= 0) {
    updatedContract._skuCount = updatedContract.sku && updatedContract.sku.length;
    updatedContract._restrictsCount = updatedContract.restricts && updatedContract.restricts.length;
    updatedContract._providersCount = updatedContract.providers && updatedContract.providers.length;
    updatedContract._structuresCount = updatedContract.structures && updatedContract.structures.length;
    state.contracts[contractIdx] = updatedContract;
  }
}

export function contracts_update_sku_dictionary(state, sku) {
  if (state.contract) {
    var existIds = state.contract.sku ? state.contract.sku.map((item) => item.drugsId) : [];
    var newSKU = sku.filter((item) => existIds.indexOf(item.drugsId) == -1);
    state.contract.sku = state.contract.sku ? state.contract.sku.concat(newSKU) : newSKU;
  }
}

export function contracts_update_structures(state, structures) {
  if (state.contract) {
    var existIds = state.contract.structures ? state.contract.structures.map((item) => item.axiomaId) : [];
    var newOrgs = structures.filter((item) => existIds.indexOf(item.axiomaId) == -1);
    state.contract.structures = state.contract.structures ? state.contract.structures.concat(newOrgs) : newOrgs;
  }
}

export function contracts_set_sku_dictionary(state, sku) {
  if (state.contract) state.contract.sku = sku;
}

export function contracts_delete(state, id) {
  var contractIdx = _.findIndex(state.contracts, function(contract) {
    return contract._id == id;
  });
  if (contractIdx >= 0) state.contracts.splice(contractIdx, 1);
}

export function contracts_set_wait(state, status) {
  state.wait = status;
}

export function contracts_set_filter_by_date_from(state, filter) {
  state.filterByDateFrom = filter;
}

export function contracts_set_filter_by_date_to(state, filter) {
  state.filterByDateTo = filter;
}
