export default function() {
  return {
    orgs: [],
    tree: [],
    childrens: [],
    parents: [],
    wait: 0,
    treeWait: 0,
    orgsImport: [],
    turnover: null,
    aliases: null,
    aliasesHistory: null,
    signatory: null,
  };
}
