import { _ } from "vue-underscore";

export function store(state) {
  return state.orgs;
}

export function wait(state) {
  return function() {
    return state.wait > 0;
  };
}

export function waitStatus(state) {
  return function() {
    if (state.wait) {
      if (state.orgs.length > 0) return vm.$i18n.translate("Рядків завантажено...") + state.orgs.length;
      else return vm.$i18n.translate("Будь ласка, зачекайте...");
    } else return "";
  };
}

export function tree(state) {
  return state.tree;
}

export function childrens(state) {
  return state.childrens;
}

export function parents(state) {
  return state.parents;
}

export function treeWait(state) {
  return function() {
    return state.treeWait > 0;
  };
}

export function treeWaitStatus(state) {
  return function() {
    if (state.treeWait) {
      if (state.tree.length > 0) return vm.$i18n.translate("Рядків завантажено...") + state.tree.length;
      else return vm.$i18n.translate("Будь ласка, зачекайте...");
    } else return "";
  };
}

export function importStore(state) {
  return state.orgsImport;
}

export function turnover(state) {
  return state.turnover;
}

export function orgsAndAliases(state) {
  return function(filter) {
    if (!state.aliases || !state.aliases.orgs) return [];
    if (filter && filter != "") {
      var parents = [];
      var resId = [];
      var res = state.aliases.orgs.filter((org) => {
        if (filter && org.name.toLowerCase().search(filter) == -1) {
          return false;
        } else {
          parents = parents.concat(org.path.map((org) => org[0]));
          resId.push(org.axiomaId);
          return true;
        }
      });
      parents = _.unique(parents);
      return res.concat(
        state.aliases.orgs.filter((parentOrg) => {
          return parents.indexOf(parentOrg.axiomaId) >= 0 && resId.indexOf(parentOrg.axiomaId) == -1;
        })
      );
    } else return state.aliases.orgs;
  };
}

export function aliases(state) {
  if (state.aliases && state.aliases.aliases) return state.aliases.aliases;
  else return [];
}

export function aliasesHistory(state) {
  return function() {
    return state.aliasesHistory ? state.aliasesHistory : [];
  };
}

export function signatory(state) {
  return function(filter) {
    if (!state.signatory) return [];
    if (filter && filter != "") {
      var res = state.signatory.filter((org) => {
        if (filter && org.name.toLowerCase().search(filter) == -1) {
          return false;
        } else {
          return true;
        }
      });
      return res;
    } else return state.signatory;
  };
}
