export default function() {
  return {
    sku: [],
    skuImport: [],
    wait: false,
    waitInfo: false,
    groups: [],
    skuInfo: null,
  };
}
