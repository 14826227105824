import md5 from "js-md5";
import { _ } from "vue-underscore";

var defaultUserSettings = {
  userSettings: { defaultCatalogLang: "ru", filterContractsByDate: "currentQuarter", floatingFilter: "false" },
  findOrgSearchBy: "axiomaId",
};

var settingsWhoNeedToReload = ["userSettings.floatingFilter"];

export function init(context, payload) {
  context.commit("init");
}

export function getServerInfo(context) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api",
      method: "GET",
    })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("update_server_info", resp.data);
          resolve(resp);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function login(context, { login, password, router }) {
  setGlobalLoading(context, $tt("Будь ласка, зачекайте..."));
  var that = this;
  return new Promise((resolve, reject) => {
    context.commit("auth_request");
    axios({
      url: "/auth/login",
      data: { login, password },
      method: "POST",
    })
      .finally(() => {
        setGlobalLoading(context, "");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          const token = resp.data.token;
          context.commit("auth_success", token);
          loadUserProfile(context, { vm: that._vm, router: router })
            .then(() => {
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          context.commit("auth_error");
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        context.commit("auth_error");
        reject(err);
      });
  });
}

export function logout({ commit }) {
  return new Promise((resolve, reject) => {
    commit("logout");
    resolve();
  });
}

export function checkLogin(context, { vm, router, next, gotoLogin }) {
  if (!context.state.timers["checkLogin"]) {
    startTimer(context, {
      name: "checkLogin",
      interval: 60 * 1000,
      callback: function() {
        checkLogin(context, { vm, router, next, gotoLogin: true });
      },
    });
  }
  if (context.state._isLoggedIn && !context.getters.isLoggedIn()) {
    context.commit("stop_timer", { name: "checkLogin" });
    if (router.currentRoute.path != "/login" && gotoLogin) {
      vm.$alert(vm.$i18n.translate("Ваша сесія завершилася! Будь ласка, увійдіть знову!"), vm.$i18n.translate("Увага"), {
        callback: (action) => {
          context.commit("logout");
          next("/login");
        },
      });
    }
  }
}

export function startTimer(context, payload) {
  context.commit("start_timer", payload);
}

export function stopTimer(context, timerName) {
  context.commit("stop_timer", { name: timerName });
}

function jsonDiff(obj1, obj2) {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== "object") {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
        const value = jsonDiff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
}

function getObjectFieldByPath(o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1");
  s = s.replace(/^\./, "");
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

function checkForChangesWhoNeedReload(data1, data2) {
  var diff = jsonDiff(data1, data2);
  if (diff) {
    var needReload = settingsWhoNeedToReload.find((path) => {
      if (getObjectFieldByPath(diff, path)) return true;
    });
    return needReload;
  } else {
    return false;
  }
}

export function updateUserSettings(context, data) {
  return new Promise((resolve, reject) => {
    var data1 = JSON.parse(JSON.stringify(context.getters.profile()));
    var oldMD5 = md5(JSON.stringify(data1));
    context.commit("update_user_settings", data);
    var data2 = JSON.parse(JSON.stringify(context.getters.profile()));
    var newMD5 = md5(JSON.stringify(data2));
    var needGloabalReload = checkForChangesWhoNeedReload(data1, data2);
    if (oldMD5 != newMD5) {
      axios({
        url: "/api/settings/set",
        data: { data: context.state.userSettings },
        method: "POST",
      })
        .then((resp) => {
          if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
            if (needGloabalReload) {
              context.commit("update_user_setting_and_reload", data);
            }
            resolve(resp);
          } else {
            reject(resp.data.msg);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      resolve({ success: true });
    }
  });
}

export function updateUserSettingsGlobal(context, data) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/settings/set",
      data: { data: data },
      method: "POST",
    })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("update_user_setting_and_reload", data);
          resolve(resp);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetUserSettingsGlobal(context) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/settings/getDefault",
      method: "POST",
    })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          updateUserSettingsGlobal(context, resp.data.data)
            .then(() => resolve(resp.data.data))
            .catch((err) => reject(err));
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateUserProfile(context, data) {
  return new Promise((resolve, reject) => {
    context.commit("update_user_profile", data);
    axios({
      url: "/api/profile/set",
      data: { data: context.state.userProfile },
      method: "POST",
    })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          resolve(resp);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loadUserSettings(context) {
  setGlobalLoading(context, vm ? vm.$i18n.translate("Завантаження налаштувань користувача...") : "Load user settings...");
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/settings/get",
      method: "POST",
    })
      .finally(() => {
        setGlobalLoading(context, "");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("update_user_settings", vm._.merge(defaultUserSettings, resp.data.data));
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loadUserProfile(context, { vm, router }) {
  setGlobalLoading(context, vm ? vm.$i18n.translate("Завантаження профілю користувача...") : "Load user profile...");
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/profile/get",
      method: "POST",
    })
      .finally(() => {
        setGlobalLoading(context, "");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("update_user_profile", resp.data.data);
          if (context.state.corp) {
            setCurrentCorp(context, { corp: context.state.corp, disableReload: true, router })
              .then((data) => {
                resolve(resp);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            getServerInfo(context).then(() => {
              loadUserSettings(context)
                .then(() => {
                  resolve(data);
                })
                .catch((err) => {
                  reject(err);
                });
            });
            resolve(resp);
          }
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function sessionExpired(vm) {
  vm.$alert(vm.$i18n.translate("Ваша сесія завершилася! Будь ласка, увійдіть знову!"), vm.$i18n.translate("Увага"), {
    callback: (action) => {
      logout().then(() => {
        //store.$router.push("/login");
      });
    },
  });
}

export function updateLocale(context, locale) {
  context.commit("update_locale", locale);
}

export function addCancelToken(context, token) {
  context.commit("add_cancel_token", token);
}

export function cancelAllRequests(context) {
  context.commit("clear_cancel_tokens");
}

export function setGlobalLoading(context, data) {
  context.commit("set_global_loading", data);
}

export function updateGlobalLoadingText(context, text) {
  context.commit("update_global_loading", text);
}

export function setCurrentCorp(context, { corp, disableReload, router }) {
  setGlobalLoading(context, $tt("Застосування оточення..."));
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/settings/corp/set",
      method: "POST",
      data: { data: { id_corp: corp.id_corp } },
    })
      .finally(() => {
        setGlobalLoading(context, "");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          const token = resp.data.token;
          context.commit("update_token", token);
          context.commit("set_corp", { corp, disableReload });
          //if (context.state.userSettings ? Object.keys(context.state.userSettings).length == 0 : true) {
          getServerInfo(context).then(() => {
            loadUserSettings(context)
              .then(() => {
                resolve(resp.data);
              })
              .catch((err) => {
                reject(err);
              });
          });
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
        context.commit("set_corp", { corp: null });
      });
  });
}

export function setPassword(context, data) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/profile/setPassword",
      data: { data: data },
      method: "POST",
    })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          resolve(resp);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loadCorps(context) {
  //  setGlobalLoading(context, $tt("Будь ласка, зачекайте..."));
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/settings/corps/get",
      method: "POST",
    })
      .finally(() => {
        //        setGlobalLoading(context, "");
      })
      .then((resp) => {
        if (resp.data && (resp.data.success == true || resp.data.success == "true")) {
          context.commit("update_corps_list", resp.data.data);
          resolve(resp.data);
        } else {
          reject(resp.data.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function downloadFile({ commit }, { fileId, fileName }) {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/file/get`,
      data: { fileId },
      method: "POST",
      responseType: "blob",
    })
      .finally(() => {})
      .then((resp) => {
        if (resp.data.success !== false) {
          const blob = new Blob([resp.data], { type: resp.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          resolve();
        } else {
          if (resp.data && resp.data.data && resp.data.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function(event) {
              try {
                var jsonErr = JSON.parse(reader.result);
                reject(jsonErr && jsonErr.msg ? jsonErr.msg : jsonErr);
              } catch (err) {
                reject(reader.result);
              }
            };
            reader.readAsText(resp.data.data);
          } else {
            reject("Unknown load error");
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function setFilterByDateFrom({ commit }, filterByDateFrom) {
  commit("contracts_set_filter_by_date_from", filterByDateFrom);
}

export function setFilterByDateTo({ commit }, filterByDateTo) {
  commit("contracts_set_filter_by_date_to", filterByDateTo);
}
